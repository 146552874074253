import { useEffect, useState, } from "react";
import TopBarRemotoWeb from "../../components/TopBarRemotoWeb";
import Footer from "../../components/Footer";
import styles from "./careers_page.module.css";
import CareersContact from "../../components/ContactForms/careers_contact";

const CareersPage = () => {
    const [selectedCard, setSelectedCard] = useState(null)
    const [animate, setAnimate] = useState(false);

    const jobData = {
        // "Multimedia Developer": {
        //     description: "Remoto is looking for a Multimedia Developer with expertise in Qt, QML, and GStreamer. Ideal for those enthusiastic about developing cutting-edge multimedia applications and optimizing their performance across various platforms, this position offers the chance to work entirely remotely.",
        //     responsibilities: [
        //         "Design and develop multimedia applications using QT and QML.",
        //         "Work with GStreamer plug-in development and troubleshoot issues.",
        //         "Implement and maintain live streaming, VOD, and video chat features.",
        //         "Apply knowledge of adaptive streaming protocols such as SRT, WebRTC or DASH.",
        //         "Optimize multimedia libraries and tools, focusing on FFmpeg and GStreamer.",
        //         "Collaborate with cross-functional teams to define, design, and ship new features.",
        //         "Ensure robustness, performance, and maintenance of applications.",
        //     ],
        //     requirements: [
        //         "Bachelor's degree in computer science or related field or equivalent experience.",
        //         "Strong programming skills in C++ and proficiency with QT and QML.",
        //         "Familiarity with at least one other programming language: C, Java, Python, Golang, or Rust (as a plus).",
        //         "Experience with multimedia tools and libraries like FFmpeg, GStreamer, and WebRTC.",
        //         "Knowledge of media servers such as Nginx RTMP module, Licode, Kurento, Mediasoup, Janus.",
        //         "Knowledge of cross-platform desktop development for macOS and Windows. Experience with Linux is a plus.",
        //         "Experience with adaptive streaming protocols (SRT, HLS, DASH).",
        //         "Proficient in using Git and familiar with Jira for project management.",
        //         "Experience with AWS services is a plus."
        //     ],
        //     link: "https://cloud.remotopro.io/s/2NCtHKTg59XCRAm"
        // },
        "Senior QA Engineer": {
            "description": "The ideal candidate will be part of a fast-moving development team launching major products at SPG Studios. We are looking for an experienced, passionate, hard-working, and talented Senior Quality Assurance Engineer who has experience building innovative and mission-critical end-to-end software applications that support our solutions.",
            "responsibilities": [
                "Perform manual frontend/backend testing across desktop applications/aws services (Windows and MacOS).",
                "Prepare clear test plans for features and stories.",
                "Identify issues in both new and existing workflows.",
                "Identify root causes of problems found in workflows.",
                "Address bugs and UX issues clearly in tickets.",
                "Communicate all findings clearly to the team and stakeholders.",
                "Document processes clearly in confluence.",
                "Contribute to onboarding processes of new team members if needed.",
                "Work closely with Development, Business, and Product Owners to ensure project success."
            ],
            "requirements": [
                "5+ years of experience with Selenium and/or Python3.",
                "5+ years of experience with UI automation.",
                "Experience testing RESTful APIs and Web Services.",
                "Strong understanding of Postgres / SQL and Database knowledge.",
                "Experience with Postman and/or curl.",
                "Knowledge of Jira and Confluence.",
                "Experience with Amazon AWS services (S3, Lambda, CloudWatch).",
                "Familiarity with streaming protocols is a plus.",
                "Creative methods to identify complex issues.",
                "Strong problem-solving skills.",
                "Clear attention to detail and organization.",
                "Strong communication skills, verbal and written.",
                "Skill in data collection and analysis.",
                "A desire to improve on existing processes.",
                "English intermediate required, advance is a plus."
            ],
            "wage_range": "$55k - $75k annually, depending on experience",
            "link": "https://cloud.remotopro.io/s/2NCtHKTg59XCRAm"
        },

    }
    const handleCardClick = (key) => {
        setSelectedCard(key);
        setAnimate(false); // Reset animation state

        // Ensuring the component re-renders before re-adding the animation class
        setTimeout(() => {
            setAnimate(true);
        }, 100);
    };

    function renderCardButton(key) {
        return (
            <div className={(selectedCard==key)?styles.sel_card:styles.card} onClick={() => { handleCardClick(key) }}>
                <h2 className={styles.buttonText}> {key} </h2>
            </div>
        )
    }

    function renderButtons(keys) {
        return (

            <div className={styles.buttonsContainer}>
                {
                    keys.map((key) => {

                        return renderCardButton(key)
                    })

                }
            </div>
        )
    }




    function renderCardDetails(key) {
        if (key) {
            return (
                <div className={`${styles.cardDetails} ${animate ? styles.fadeIn : ''}`}>
                    <div className={styles.horizontal} style={{ width: "100%", justifyContent: 'space-between' }}> <h3 className={styles.titleText}> {key} </h3> <p className={styles.xButton} onClick={() => handleCardClick(null)}>{'+'}</p> </div>
                    <p> Description: </p>
                    <p> {jobData[key].description} </p>
                    <p> Responsibilities: </p>
                    <p> {jobData[key].responsibilities.map((item) => {
                        return <li> {item} </li>
                    })} </p>
                    <p> Requirements: </p>
                    <p> {jobData[key].requirements.map((item) => {
                        return <li> {item} </li>
                    })} </p>
                    <p> Wage Range: </p>
                    <p> {jobData[key].wage_range} </p>
                    {/* <p style = {{paddingTop: '0px', alignSelf:"center", bottom: '0%'}}> For a comprehensive outline of the role  <a src = {jobData[key].link}>read the full job description here</a></p> */}
                </div>
            )
        }
    }
    useEffect(() => { }, [selectedCard])
    return (
        <div className = {styles.parentComponent}>
            <TopBarRemotoWeb />
            

            
            <div className={styles.mainComponent}>
                <h1 className = {styles.header}> Open Positions </h1>

                <div className={styles.navContainer}>
                    {renderButtons(Object.keys(jobData))}
                    <div className={styles.contentContainer}>
                        {renderCardDetails(selectedCard)}


                        {!selectedCard && <div className={styles.titleContainer}>
                            <h1 className={styles.titleHeader}>Join Our Journey at Remoto</h1>

                            <p><strong> Our Mission:</strong> <br />
                                Remoto is a leader in the rapidly evolving confluence of media creation and consumption, and the redefining of collaboration and post-production workflows. We believe in starting from scratch and doing things the right way to build outstanding software that delivers on the needs and requirements of customers, swiftly and efficiently utilizing in-house AI and robust infrastructure.                     </p>
                            <br />
                            <p><strong> Who We Are:</strong> <br />
                                We are a powerful collective of dreamers, tinkerers, explorers, and innovators, and we invite you to be a part of our creative revolution.
                            </p>
                            <h1 className={styles.titleHeader}>Our Values</h1>

                            <p><strong>Ownership:</strong> <br />
                                At Remoto, you play a key role in building exceptional products that not only meet customer needs but exceed expectations, products that keep you engaged and proud.</p>
                            <br />
                            <p><strong>Velocity:</strong> <br />
                                We thrive on rapid development and continuous improvement, understanding that our success is driven by our ability to make quick decisions, execute efficiently, and invest in scalable systems.</p>
                            <br />
                            <p><strong>Utility:</strong> <br />Amidst innovation, our goal is to transform cutting-edge technology into practical, everyday tools that millions of creative professionals rely on.</p>
                        </div>}

                        <div className={styles.mainChild}>



                            <div className={styles.actionTextContainer}>
                                <h3 className={styles.titleText}> Perks and Benefits</h3>

                                <ul>
                                    <li>Flexible Working Hours: Embrace work-life harmony.</li>
                                    <li>Remote Work Opportunities: Find your best work environment.</li>
                                    <li>Competitive Salaries and Benefits: Enjoy rewarding compensation and benefits.</li>
                                    <li>Health and Wellness Programs: Participate in programs that support your well-being.</li>
                                </ul>
                                <h3 className={styles.titleText}> Application Process </h3>



                                <p>
                                    Interested applicants are invited to send the following:</p>
                                <ul>

                                    <li>CV / Resume</li>
                                    <li>Brief introduction</li>
                                    <li>Link to GitHub profile (if available)</li>
                                </ul>
                                
                                <CareersContact />
                               
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
           
        </div>
    )
}

export default CareersPage