import React, { FunctionComponent } from "react";
import styles from "./CollaborationFrame.module.css";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";


const CollaborationFrame: FunctionComponent = () => {
  useCalendlyEventListener({
  onProfilePageViewed: () => console.log("onProfilePageViewed"),
  onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
  onEventTypeViewed: () => console.log("onEventTypeViewed"),
  onEventScheduled: (e) => console.log(e.data.payload)
});

  return (
    <div className={styles.centerFrame} id="main">
     
      <div className={styles.textframeParent} id="NABcalendly">
      
        <div className={styles.textframe} id="textframe">
        <h1 className={styles.areYouAttendingContainer}>
            <span>Schedule a Demo</span>
          
          </h1>
          <p className={styles.scheduleTheTimeContainer}>
            <span>{`Choose a time that works for you, and we'll show you how `}</span>
            <b>Remoto Playback</b>
            <span> can</span>
            <b> revolutionize your media workflows</b>
            <span>.</span>
          </p>
          
        </div>
        <div className={styles.framecalendly} id="calendly container">
           <InlineWidget
           url="https://calendly.com/remotopro"
           pageSettings={{
       
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
   
            }}/>
        </div>
        
      </div>

        
    </div>
  );
};

export default CollaborationFrame;
