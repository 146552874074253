import React, { FunctionComponent } from "react";
import CollaborationFrame from "../../components/CollaborationFrame";
import styles from "./PortalUIUXCalendlyLandin.module.css";
import TopBarRemotoWeb from "../../components/TopBarRemotoWeb";
import Footer from "../../components/Footer";

const PortalUIUXCalendlyLandin: FunctionComponent = () => {
  return (
    <main className={styles.portaluiuxCalendlyLandin}>
      <TopBarRemotoWeb />
      <CollaborationFrame />
      <Footer/>
    </main>
  );
};

export default PortalUIUXCalendlyLandin;
