import Footer from "../../components/Footer";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";

import { useEffect, useState } from "react";
import TopBarRemotoWeb from "../../components/TopBarRemotoWeb";

import MediaTest from "../../components/Network_Test/network_test_component";


const ConnectionTestPage = () => {

    return (
        <div style = {{display: 'flex', flexDirection: 'column', minHeight: '100vh', height: 'auto', justifyContent: 'space-between'}}>
        <TopBarRemotoWeb/>
        
        <MediaTest/>
       
        <Footer/>
        </div>
        
       
    )

}

export default ConnectionTestPage