import React, { useState, useRef } from 'react';
import styles from "./HelpComponent.module.css";

const DanteChatBox = ({isOpen, toggleChatBox})  => {
    const targetRef = useRef(null);
    const scrollToTarget = () => {
        setBotOpen(!botOpen)

        if (targetRef.current) {
          targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };
    return(
       <>
       
            <div id = 'targetElement' ref = {targetRef}
            className = {`${styles.DanteContainer} ${styles.fadeIn}`}>
      
        
    

        
        
            <iframe 
            src="https://chat.dante-ai.com/embed?kb_id=42140e26-36bc-4615-bb09-c43c49e29d52&token=d33fdc88-0ad6-4f7d-ad30-573c3550f1d7&modeltype=gpt-4&logo=ZmFsc2U=&mode=undefined"
            allow="clipboard-write; microphone"
            width = "400px"
            height="400px"
            frameborder="0"
            title="Dante Chatbot"


            ></iframe>
           
           
           
           
           </div>
         </>
    )

}
export default DanteChatBox