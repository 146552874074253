import { useEffect, useState, useRef } from "react";
import TopBarRemotoWeb from "../../components/TopBarRemotoWeb";
import Footer from "../../components/Footer";
import ResourcesComponent from "../../components/ResourcesComponent/ResourcesComponent";
const ResourcesPage = () => {
    
      useEffect(() => {
     window.scrollTo(0, 0);
      }, []);
    
      return (
        <div style = {{height: "100vh", overflowY: "auto"}}> 
   <TopBarRemotoWeb/>
   <div style = {{overflowY: 'auto'}} > 
   <ResourcesComponent/>

   <Footer/>
   </div>
    </div>

      );


}

export default ResourcesPage;