import Footer from "../../components/Footer";
import styles from "./landing_page.module.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";

import { useEffect, useState } from "react";
import TopBarRemotoWeb from "../../components/TopBarRemotoWeb";
import SupportContact from "../../components/ContactForms/support_contact";
import HelpComponentBot from "../../components/HelpComponent/HelpComponent_bot";
const HelpBotPage = () => {

  useEffect(() => {}, []);

  return (
    <div style = {{display: 'flex', flexDirection: 'column', alignItems:'space-between', height: '100vh'}}>
    <TopBarRemotoWeb></TopBarRemotoWeb>
    <HelpComponentBot></HelpComponentBot>
    <div style = {{bottom: '0%', width: 'auto'}}> <Footer /> </div> 
  </div>
  );
};

export default HelpBotPage;

