import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./global.css";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { Amplify } from 'aws-amplify';

import awsconfig from './aws-exports';

import "@aws-amplify/ui-react/styles.css";
import { studioTheme } from "./ui-components";

Amplify.configure(awsconfig);


import { createTheme } from "@aws-amplify/ui-react";


const updatedTheme = createTheme({
    // Extend the theme to update the button color
    name: "my-theme-updates", 
    tokens: {
        components: {
            button: {
                primary: {
                    backgroundColor: {
                        value: "var(--orange)"
                    },
                },
            },
          
        },
        
          colors: {
            font: {
              primary: { value: 'white' },
             
           
            secondary: { value: 'white' },
              error: {value: 'red'}
            }
          },
          fonts: {
            default: {
              variable: { value: 'var(--font-proxima-nova)' },
              static: { value: 'var(--font-proxima-nova)' },
            },
          },
      
    },
}, studioTheme)


const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <BrowserRouter>
  <ThemeProvider theme={updatedTheme}>
    <App />
    </ThemeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
