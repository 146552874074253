import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './ContactForms.module.css';
import { useState } from 'react';

const CareersContact = () => {
    const [fileName, setFileName] = useState('');
    const [didSubmit, setDidSubmit] = useState(false);
    const [base64File, setBase64File] = useState('');
    const [isReading, setIsReading] = useState(false);

    const [submissionResult, setSubmissionResult] = useState(''); // This will be set to either 'Success' or 'Error' after form submission
    const handleFileChange = (event) => {
        setIsReading(true);
        const file = event.currentTarget.files[0];
        if (file) {
            setFileName(file.name);
            setBase64File(file)
            setIsReading(false)
        } else {
            setBase64File('');
            setFileName('');
            formik.setFieldValue('attachment', null);
            setIsReading(false)
        }
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            subject: '',
            content: '',
            attachment: null
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            subject: Yup.string().required('Subject is required'),
            content: Yup.string().required('Content is required'),
        }),
        onSubmit: (values) => {
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('email', values.email);
            formData.append('subject', values.subject);
            formData.append('content', values.content);
            formData.append('attachment', base64File);
            if (!isReading){
            fetch(process.env.REACT_APP_ZAPIER_CAREERS_HOOK, {
                method: 'POST',
                body: formData,
            })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setDidSubmit(true);
                setSubmissionResult('Success');
                
            })
            .catch(error => {
                console.error('Error:', error);
                
                setDidSubmit(true);
                setSubmissionResult('Error');
            });
        }
        else {
            alert("Please wait for the file to finish uploading")
        }
    }
    });

    const handleFileButtonClick = () => {
        document.getElementById('hiddenFileInput').click();
    };

    return (
        <div className = {styles.formCard}>
            {!didSubmit &&
            <form className={styles.mainForm} onSubmit={formik.handleSubmit}>
                <label htmlFor="name">Name</label>
                <input
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    className={styles.formFieldIn}
                    required
                />
                {formik.errors.name && formik.touched.name ? (
                    <div className={styles.formError}>{formik.errors.name}</div>
                ) : null}

                <label htmlFor="email">Email</label>
                <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    className={styles.formFieldIn}
                    required
                />
                {formik.errors.email && formik.touched.email ? (
                    <div className={styles.formError}>{formik.errors.email}</div>
                ) : null}

                <label htmlFor="subject">Subject</label>
                <input
                    id="subject"
                    name="subject"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.subject}
                    className={styles.formFieldIn}
                    required
                />
                {formik.errors.subject && formik.touched.subject ? (
                    <div className={styles.formError}>{formik.errors.subject}</div>
                ) : null}

                <label htmlFor="content">Content</label>
                <textarea
                    id="content"
                    name="content"
                    onChange={formik.handleChange}
                    value={formik.values.content}
                    className={styles.formFieldIn}
                    required
                />
                {formik.errors.content && formik.touched.content ? (
                    <div className={styles.formError}>{formik.errors.content}</div>
                ) : null}

                <input
                    id="hiddenFileInput"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
                <button type="button" onClick={handleFileButtonClick} className={styles.customFileUpload}>
                    {fileName || 'Upload File'}
                </button>

                <button type="submit" className={styles.submitButton}>
                    Submit
                </button>
            </form>}
          
                        {didSubmit && (
                            <div className={styles.mainForm}>
                                {submissionResult === 'Success' ? (
                                    <div style = {{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                        <img src={'/icons/Success_Green_REMT.png'} />
                                        <h3>Thank you for your submission</h3>
                                    </div>
                                ) : (
                                    <div style = {{display: 'flex', flexDirection: 'row'}}>
                                        <img src={'/icons/Failure_Red_REMT.png'} />
                                        <h3>Something went wrong. Please try again, or email us directly at careers@remotopro.io </h3>
                                    </div>
                                )}
                            </div>
                        )}
                    
        </div>
    );
};

export default CareersContact;
