import { useNavigate } from "react-router-dom";
import TopBarRemotoWeb from "../../components/TopBarRemotoWeb"
import { Container } from "../../wrappers/container"
import styles from "./styles.module.scss"
import { useCallback, useState, useEffect } from "react";
import Footer from "../../components/Footer";
import { featureItems } from "../../constants";
import FeaturesComponent from "../../components/FeaturesComponent";
import StoreComponent from "../../components/StoreComponent/store_component";

const PlaybackZero = () => {
    const [selectedFeature, setSelectedFeature] = useState(null)
    const [selectedList, setSelectedList] = useState<any>(null)
    const [isVisible, setIsVisible] = useState(false)
    const [baseURL, setBaseURL] = useState("")
    const [featureHandle, setFeatureHandle] = useState(false)
    const [env, set_env] = useState('');  
    const navigate = useNavigate();

    const onMenuButton6Click = useCallback(() => {
        navigate('/download')
      }, []);

    const onMenuButton3Click = useCallback(() => {
        navigate('/download')
      }, []);

      const bulletPoints = {
        'Highest quality video live streams': [
            '/public/icons/features/LiveStreaming.svg',
          'Up to 4K at 60fps',
          'Supports streaming files using multiple video codecs including AV1',
          'Source video feeds can be software based, like NDI or local screen capture, or hardware based, like SDI or HDMI using Blackmagic or AJA',
          'Showcase your work from your favorite creative software like Pro Tools, Final Cut, Avid Media Composer, DaVinici Resolve, Maya, Flame, and Adobe Creative Cloud apps like Premiere Pro and After Effects',
          'Synchronized playback controls make sure everyone sees the same content with ultra-low latency',
        ],
        'High quality audio': [
          'Live stream frame-accurate sound editing, mixing and music composition sessions',
          'Supports compressed or uncompressed formats',
          'Full spectrum, multi-channel 5.1 or 7.1 surround sound, or up to 20 audio discrete channels to enable creation of Dolby Atmos content',
          'Ultra-low sub-second latency (sub 500ms)',
        ],
        'Powerful collaboration features': [
          'Built-in voice and video chat; no need to manage a separate video call',
          'Time-coded comments can be exported as a track on a timeline',
        ],
        'Streamlined for speed': [
            'Easy-to-use secure dashboard and admin panel',
            'Quickly provision guests and schedule review sessions',
            'Set up sessions in 60 seconds or less',
            'Easily manage your workspace with the flexibility to assign or reassign seats on the fly',
          ],
          'Integral, comprehensive security': [
            'Streams are AES 256 encrypted and watermarked',
            'Utilizes TPN, CDSA, GDPR, and HIPAA compliant cloud infrastructure for the real-time communications',
            'Supported by all major studios, networks, post production houses and content creators around the world, including video game developers and advertising agencies',
            'Guests are individually admitted by the host to eliminate the risk of password sharing, and multifactor authentication (MFA) can be enforced',
          ],
          'Powerful Integrations & Perks': [
            'Dolby.io audio communications APIs dramatically enhance video call quality',
            'No proprietary hardware needed',
            '24/7 white-glove tech support',
          ],
      };



    function handleClick(item: any, target: string){
        let div = document.getElementById(target)
        const descriptionBlock: any = new DOMParser().parseFromString('<div><p>test</p></div>', 'text/html')

        div?.append(descriptionBlock.documentElement)
        console.log('div ', div)
        console.log('item ', item)
        setFeatureHandle(item => !item)
        setFeatureHandle(!featureHandle)
        console.log('featureHandkle ', featureHandle)
        
    }
    const getEnv = async () => {
      let value = "";
      const url = window.location.hostname;
      console.log(url);
  
      if (url === "localhost") {
        set_env("stage.");
        value = "stage.";
      } else {
        const subdomainValue = url.split(".")[0];
  
        if (subdomainValue === "beta" || subdomainValue === "stage") {
          set_env(subdomainValue + ".");
          value = subdomainValue + ".";
        }
      }
      console.log(value);
      return value;
    };
    useEffect(()=>{
      getEnv()
      .then((value) => {
        setBaseURL(`https://cdn.${value}remotopro.io`);
      })
    },[])

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
      };

      const handleEnterprisePlusContact = useCallback(() => {
        const subject = encodeURIComponent("Learn More About Remoto Playback Enterprise+");
       
        window.location.href = `mailto:sales@remotopro.io?subject=${subject}`;
        
      }, []);
    
    





    return (
        <div className={styles.remotoPlayback}>
            <TopBarRemotoWeb />
            <div className={styles.scrollContainer}>
            <div className={styles.headerFrameBack}>
                <header className={styles.header} style = {{paddingTop: '60px'}}>
                    <Container>
                    <img
          className={styles.remotosystemlogoIcon}
          alt=""
          src="Remoto PB 1 line.png"
        />
                        <h1 className={styles.headerTitle}> Studio quality, wherever you are.</h1>
                       
                        <img className={styles.headerImage} alt="" src={`${baseURL}/web-assets/Products/PlaybackManMarch2024.png`} />
                
                        <section className={styles.feats}>
                            <div className={styles.featsBlockRev}>
                                <div className={styles.featsTextBlock}>
                                    <h2 className={`${styles.featsTextBlockTitle} ${styles.left}`}>
                                        Collaborate from Anywhere
                                    </h2>
                                    <p className={`${styles.featsTextBlockText} ${styles.left}`}>
	
                                    Collaborate and create in real time, using a studio-quality tool that makes remote teams feel like they’re together onsite. Powerful scheduling and user management features streamline planning, organizing, and hosting sessions. 100% software based - no external hardware required - with data security that meets the industry’s highest standards.                                    </p>
                                </div>
                                <img
                                    className={styles.featsImage}
                                    alt="Collaborate from Anywhere"
                                    src={`${baseURL}/web-assets/Products/Collaboration+Remoto.gif`}
                                />
                            </div>
                            <div className={styles.featsBlock}>
                                <img
                                    className={styles.featsImage}
                                    alt="Studio-Quality Remote Experience"
                                    src={`${baseURL}/web-assets/Products/Secure.gif`}
                                />
                                <div className={styles.featsTextBlock}>
                                <h2 className={`${styles.featsTextBlockTitle} ${styles.right}`}>
                                        Studio-Quality Remote Experience
                                    </h2>
                                    <p className={`${styles.featsTextBlockText} ${styles.right}`}>
                                    Live stream your sound, editorial, and VFX sessions with high quality frame-accurate audio and video, end-to-end security, and integrated communications tools to make reviewing, commenting, and collaborating a snap                                    </p>                        </div>
                              
                            </div>
                            <div className={styles.featsBlockRev}>
                                <div className={styles.featsTextBlock}>
                                    <h2 className={`${styles.featsTextBlockTitle} ${styles.left}`}>
                                        Unmatched Audio Performance
                                    </h2>
                                    <p className={`${styles.featsTextBlockText} ${styles.left}`}>
                                    Remoto Playback is the only review-and-approve software that supports 16 channels of discrete audio without additional hardware. Collaborate without constraints in Dolby Atmos and other spatial formats.         </p>                       </div>
                              
                                <img
                                    className={styles.featsImage}
                                    alt="Unmatched Audio Performance"
                                    src={`${baseURL}/web-assets/Products/Audio-Sync.gif`}
                                />
                            </div>
                        </section>
                    </Container>
                </header>
            </div>
            <section className={styles.perfectBlock}>
                <Container>
                    <div className={styles.content}>
                        <div className={styles.contentRow}>
                            <h2 className={styles.contentTitle} style = {{textAlign: 'right'}}>Perfect<br/>for</h2>
                            <img
                                className={styles.contentImage}
                                alt="Perfect for"
                                src={`${baseURL}/web-assets/Products/infinite%20scroll.gif`}
                            />
                        </div>
                        <p className={styles.contentDescription}>
                            Like all Remoto software, feature-rich Playback was designed and
                            built by experienced post-production professionals to deliver
                            the level of performance necessary to balance creative work with
                            tight deadlines.
                        </p>
                    </div>
                </Container>
            </section>
            <section className={styles.features}>
                
             
                    <h1 className={styles.whyRemoto} id="outline title">
                          Features
                    </h1>
                    <FeaturesComponent startVal = {0} endVal = {5} target = 'upper'/>
                
                
               
            </section>
           
            <Footer/>
            </div>
        </div>
    )
}

export default PlaybackZero

