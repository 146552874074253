import Footer from "../../components/Footer";
import styles from "./landing_page.module.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";

import { useEffect, useState } from "react";
import TopBarRemotoWeb from "../../components/TopBarRemotoWeb";
import HelpComponent from "../../components/HelpComponent/HelpComponent";
import SupportContact from "../../components/ContactForms/support_contact";
import HelpComponentForm from "../../components/HelpComponent/HelpComponent_form";

const HelpFormPage = () => {

  useEffect(() => {}, []);

  return (
   <div styles = {{height: '100vh', overflow: 'hidden'}}> 
      <TopBarRemotoWeb/>
      <div>
      <HelpComponentForm></HelpComponentForm>
      <Footer /> 
    </div>
    </div>
   
  );
};

export default HelpFormPage;


