import { useEffect, useState, useRef, useContext } from "react";
import styles from "./LegalComponent.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { StoreContext } from "../../context/StoreContext";


const LegalComponent = (props) => {
  const { getEnv } = useContext(StoreContext);

  const [baseURL, setBaseURL] = useState(``); 
  useEffect(()=>{
  
  let env = getEnv().then((value) => {setBaseURL(`https://cdn.${value}remotopro.io`)});
  },[baseURL]) 

  const documentKey = {
    "Acceptable Use Policy":
      `/web-assets/documents/AcceptableUsePolicy022024.html`,
    "Data Processing Agreement":
      `/web-assets/documents/DataProcessingAgreement022024.html`,

    "Privacy Policy":
      `/web-assets/documents/PrivacyPolicy022024.html`,

   
    "Terms of Service":
      `/web-assets/documents/TermsofService042024.html`,
    "Website Terms of Use":
      `/web-assets/documents/WebsiteTermsofUse042024.html`,


  };

  const pdfKey = {
    "Acceptable Use Policy":
    `/web-assets/documents/PDF/RemotoPlaybackAcceptableUsePolicy.pdf`,
  "Data Processing Agreement":
    `/web-assets/documents/PDF/RemotoPlaybackDataProcessingAgreement.pdf`,

  "Privacy Policy":
    `/web-assets/documents/PDF/RemotoPlaybackPrivacyPolicy.pdf`,

 
  "Terms of Service":
    `/web-assets/documents/PDF/RemotoPlaybackTermsofService.pdf`,
  "Website Terms of Use":
    `/web-assets/documents/PDF/RemotoPlaybackWebsiteTermsofUse.pdf`,

  }
 

  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const doc = query.get('doc');

  function docToDocument(doc) {
    if (doc) {
      let words = doc.split("_")
      let capitalized = words.map((word) => { if (word !== 'of') { return word.charAt(0).toUpperCase() + word.slice(1) } else { return word } })
      let out_val = capitalized.join(" ")

      return out_val
    }
  }


  const [selectedDocument, setSelectedDocument] = useState(doc ? docToDocument(doc) : "Acceptable Use Policy");
  const [documentData, setDocumentData] = useState(documentKey);
  const navigate = useNavigate();
  const [query_doc, setQueryDoc] = useState(docToDocument(doc));
  const resources_outlet = ['White Paper', 'Subprocessors List']

  useEffect(() => {
    if (resources_outlet.includes(query_doc)) {
      navigate(`/resources?doc=${query_doc}`);
    }

  }, [query_doc, navigate]);


  //need functions for each document to fetch the urls
  // const fetchTermsAndConditions = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://api.stage.remotopro.io/v1/terms-and-conditions/active",
  //       {
  //         headers: {
  //           accept: "application/json",
  //           SolutionContext: "WEB",
  //         },
  //       }
  //     );

  //     const data = await response.json();
  //     //override the terms and conditions url with the fetched one
  //     let docs = {...documentData}
  //     docs["Terms of Use"] = data.url
  //     setDocumentData(docs)

  //   } catch (error) {
  //     console.error("Error fetching terms and conditions:", error);
  //   }
  // };

  // // Call the function wherever you need to fetch the terms and conditions
  // useEffect(() => {
  //   fetchTermsAndConditions();
  // }, []);

  // Rest of your code







  const renderDoc = (sel_doc) => {
    const url = documentData[sel_doc]
    return (
      <div className={styles.iFrame}>
        <iframe src={`${baseURL}${url}`} title="iFrame" style={{ display: 'flex', overflow: 'auto', border: '0px', width: "100%", height: "100%" }} />
      </div>
    );
  };

  return (

    <div className={styles.legalParent} >

    <h1 className = {styles.headerTop}>
      Legal
      </h1>
      
      <div className={styles.horizontal}>
        <div>
          <div className={styles.legalChild} >
            
            {Object.keys(documentData).map((doc) => (
              <button className={(doc == selectedDocument) ? styles.buttonSelected : styles.buttonOff} key={doc} onClick={() => { setSelectedDocument(doc) }}>
                {(doc === "White Paper") ? 'Security White Paper' : doc}
                {doc === selectedDocument && <a id = "downloadPdf" href = {`${baseURL}${pdfKey[selectedDocument]}`} target = '_blank' download className={styles.downloadPdfButton}>
                    <img style = {{width: '20px'}} src="/icons/download_simple.svg" alt="Download" />
                </a>}
              </button>

            ))}
          </div>
        
        </div>
        {selectedDocument != 'none' && renderDoc(selectedDocument)}
      </div>
             
    </div>
  );

}

export default LegalComponent