// DataContext.js
import React, { createContext, useState } from 'react';

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
    const [cart, setCart] = useState('');
    const [currentPlan, setCurrentPlan] = useState('')
    const [userToken, setUserToken] = useState('')
    const [userAction, setUserAction] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [env, setEnv] = useState((process.env.REACT_APP_ENV == 'prod.')? "": process.env.REACT_APP_ENV)
    const [challengeId, setChallengeId] = useState('')
    const [notifyObject, setNotifyObject] = useState({
      message: '',
      code: '',
      type: ''
    })
    const getEnv = async () => {
        let value = (process.env.REACT_APP_ENV == 'prod.')? "": process.env.REACT_APP_ENV;
       
       
      // console.log("URL:", url)
      //   if (url === "remotopro.io") {
      //     setEnv("");
      //     return ("")
      //   }
      //   if (url === "localhost") {
      //     let tempVal = "stage.remotopro.io"
      //     const subdomainValue = tempVal.split(".")[0];
      //     console.log("SUBDOMAIN:", subdomainValue);

      //     if (subdomainValue === "beta" || subdomainValue === "stage") {
      //       setEnv(subdomainValue + ".");
      //       value = subdomainValue + ".";
      //     }
      //   } else {
      //     const subdomainValue = url.split(".")[0];
      //     console.log("SUBDOMAIN:", subdomainValue);

      //     if (subdomainValue === "beta" || subdomainValue === "stage") {
      //       setEnv(subdomainValue + ".");
      //       value = subdomainValue + ".";
      //     }
      //   }
        console.log("ENVIORNMENT:", value);
        return value;
      };

    return (
        <StoreContext.Provider value={{ 
            cart, setCart, 
            currentPlan, setCurrentPlan, 
            userToken, setUserToken,
            userAction, setUserAction,
            userEmail, setUserEmail,
            env, getEnv, 
            challengeId, setChallengeId,
            notifyObject, setNotifyObject
            }}>
            {children}
        </StoreContext.Provider>
    );
};
