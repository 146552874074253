import Footer from "../../components/Footer";
import styles from "./landing_page.module.css";
import os from "os";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";

import { useEffect, useState } from "react";
import TopBarRemotoWeb from "../../components/TopBarRemotoWeb";
import DownloadPageComponent from "../../components/download_page_component";

const LandingPage = () => {
  const [download_state, set_download_state] = useState(
    "Click here to download"
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [sessionId] = useState(searchParams.get("session_id"));
  const [companyId] = useState(searchParams.get("company_id"));
  const [solution, set_solution] = useState(searchParams.get("solution"));
  const [action] = useState(searchParams.get("email_type"));
  const [email] = useState(searchParams.get("email"));
  const [arch, set_arch] = useState("none");
  const [platform, set_platform] = useState("none");
  const [platform_version, set_platform_version] = useState("none");

  return (
    <div    style={{
      width: "auto",
      height: "100vh",
      minHeight: 'min-content',
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
    }}> 
   
      <TopBarRemotoWeb />

      <DownloadPageComponent />

      <Footer />
      

    </div>
  );
};

export default LandingPage;

