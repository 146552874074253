import styles from "./terms_page.module.css";

const TermsComponent = () => {

    return (
        <div className = {styles.centerFrame}>
        <div className={styles.label}>
        <div className={styles.termsWrapper}>
          <h1 className={styles.termsConditions}>
            <span className={styles.textWrapper}>Terms of Use for </span>
            <span className={styles.span}>REMOTE STUDIO INC.</span>
          </h1>
          <h2 className={styles.textWrapper}>
            {" "}
            Last updated on May 05, 2023
          </h2>
        </div>
      </div>

      <div className={styles.bodyText}>
        <h2> 1. BINDING EFFECT. </h2>
        <p>
          {" "}
          This is a binding agreement. By using the Internet site located at
          remotopro.io (the “Site”) or any services provided in connection
          with the Site (the “Service”), you agree to abide by these Terms of
          Use, as they may be amended by Remote Studio Inc. (“Company”) from
          time to time in its sole discretion. Company will post a notice on
          the Site any time these Terms of Use have been materially changed or
          otherwise updated. It is your responsibility to review these Terms
          of Use periodically, and if at any time you find these Terms of Use
          unacceptable, you must immediately leave the Site and cease all use
          of the Service and the Site. YOU AGREE THAT BY USING THE SERVICE YOU
          REPRESENT THAT YOU ARE AT LEAST 18 YEARS OLD AND THAT YOU ARE
          LEGALLY ABLE TO ENTER INTO THIS AGREEMENT.
        </p>
        <h2> 2. PRIVACY POLICY.</h2>
        <p>
          {" "}
          Company respects your privacy and permits you to control the
          treatment of your personal information. A complete statement of
          Company’s current privacy policy can be found by clicking here.
          Company’s privacy policy is expressly incorporated into this
          Agreement by this reference.
        </p>
        <h2>3. USE OF SOFTWARE. </h2>
        <p>
          {" "}
          Company may make certain software available to you from the Site. If
          you download software from the Site, the software, including all
          files and images contained in or generated by the software, and
          accompanying data (collectively, “Software”) are deemed to be
          licensed to you by Company pursuant to Company’s License Agreement,
          for use in conformance with these Terms of Use, the License
          Agreement, and any other license or other agreement relating to your
          use of the Software. Company does not transfer either the title or
          the intellectual property rights to the Software, and Company
          retains full and complete title to the Software as well as all
          intellectual property rights therein. You may not sell,
          redistribute, or reproduce the Software, nor may you decompile,
          reverse-engineer, disassemble, or otherwise convert the Software to
          a human-perceivable form. All trademarks and logos are owned by
          Company or its licensors and you may not copy or use them in any
          manner. To the extent that there is any difference between the these
          Terms of Use and any agreement specific to any Software, the terms
          of the agreements relating to the Software shall control.
        </p>
        <h2> 4. USER CONTENT. </h2>
        <p>
          {" "}
          To the extent that you provide suggestions, comments or other
          content relating to the Site or Service or Company you grant Company
          a license to use such materials. By transmitting, or otherwise
          distributing information or other content (“User Content”) relating
          to the Site or Service, you are granting Company, its affiliates,
          officers, directors, employees, consultants, agents, and
          representatives a license to use User Content in connection with the
          operation of Company’s business, including without limitation, a
          right to copy, distribute, transmit, publicly display, publicly
          perform, reproduce, edit, translate, and reformat User Content. You
          will not be compensated for any User Content. You agree that Company
          may publish or otherwise disclose your name in connection with your
          User Content. By providing User Content, you warrant and represent
          that you own the rights to the User Content or are otherwise
          authorized to display, perform, transmit, or otherwise distribute
          User Content.
        </p>
        <h2> 5. COMPLIANCE WITH INTELLECTUAL PROPERTY LAWS. </h2>
        <p>
          {" "}
          When accessing the Site or using the Service, you agree to obey the
          law and to respect the intellectual property rights of others. Your
          use of the Service, Software and the Site is at all times governed
          by and subject to laws regarding copyright ownership and use of
          intellectual property. You agree not to upload, download, display,
          perform, transmit, or otherwise distribute any information or
          content (collectively, “Content”) in violation of any third party’s
          copyrights, trademarks, or other intellectual property or
          proprietary rights. You agree to abide by laws regarding copyright
          ownership and use of intellectual property, and you shall be solely
          responsible for any violations of any relevant laws and for any
          infringements of third party rights caused by any Content you
          provide or transmit, or that is provided or transmitted using your
          User ID. The burden of proving that any Content does not violate any
          laws or third party rights rests solely with you.
        </p>
        <h2>6. COPYRIGHT INFRINGEMENT. </h2>
        <p>
          {" "}
          Company has in place certain legally mandated procedures regarding
          allegations of copyright infringement occurring on the Site or with
          the Service. Company has adopted a policy that provides for the
          immediate suspension and/or termination of any Site or Service user
          who is found to have infringed on the rights of Company or of a
          third party, or otherwise violated any intellectual property laws or
          regulations. Company’s policy is to investigate any allegations of
          copyright infringement brought to its attention. If you have
          evidence, know, or have a good faith belief that your rights or the
          rights of a third party have been violated and you want Company to
          delete, edit, or disable the material in question, you must provide
          Company with all of the following information: (a) a physical or
          electronic signature of a person authorized to act on behalf of the
          owner of the exclusive right that is allegedly infringed; (b)
          identification of the copyrighted work claimed to have been
          infringed, or, if multiple copyrighted works are covered by a single
          notification, a representative list of such works; (c)
          identification of the material that is claimed to be infringed or to
          be the subject of infringing activity and that is to be removed or
          access to which is to be disabled, and information reasonably
          sufficient to permit Company to locate the material; (d) information
          reasonably sufficient to permit Company to contact you, such as an
          address, telephone number, and if available, an electronic mail
          address at which you may be contacted; (e) a statement that you have
          a good faith belief that use of the material in the manner
          complained of is not authorized by the copyright owner, its agent,
          or the law; and (f) a statement that the information in the
          notification is accurate, and under penalty of perjury, that you are
          authorized to act on behalf of the owner of an exclusive right that
          is allegedly infringed. For this notification to be effective, you
          must provide it to Company’s designated agent at:
        </p>
        <br />
        <p>
          {" "}
          Remote Studio Inc.
          <br />
          919 N Victory Blvd. <br />
          Burbank, CA 91502
          <br />
          Attn: Remoto Info
          <br />
          Email: info@remotopro.io{" "}
        </p>
        <br />
        <h2>7. ALLEGED VIOLATIONS. </h2>
        <p>
          {" "}
          Company reserves the right to terminate your use of the Service
          and/or the Site. To ensure that Company provides a high quality
          experience for you and for other users of the Site and the Service,
          you agree that Company or its representatives may access your
          account and records on a case-by-case basis to investigate
          complaints or allegations of abuse, infringement of third party
          rights, or other unauthorized uses of the Site or the Service.
          Company does not intend to disclose the existence or occurrence of
          such an investigation unless required by law, but Company reserves
          the right to terminate your account or your access to the Site
          immediately, with or without notice to you, and without liability to
          you, if Company believes that you have violated any of the Terms of
          Use, furnished Company with false or misleading information, or
          interfered with use of the Site or the Service by others.
        </p>
        <h2> 8. NO WARRANTIES. </h2>
        <p>
          {" "}
          Company hereby disclaims all warranties. Company is making the site
          available “as is” without warranty of any kind. You assume the risk
          of any and all damage or loss from use of, or inability to use, the
          site or the service. To the maximum extent permitted by law, company
          expressly disclaims any and all warranties, express or implied,
          regarding the site, including, but not limited to, any implied
          warranties of merchantability, fitness for a particular purpose, or
          noninfringement. Company does not warrant that the site or the
          service will meet your requirements or that the operation of the
          site or the service will be uninterrupted or error-free.{" "}
        </p>
        <h2> 9. LIMITED LIABILITY. </h2>
        <p>
          {" "}
          Company’s liability to you is limited. To the maximum extent
          permitted by law, in no event shall company be liable for damages of
          any kind (including, but not limited to, special, incidental, or
          consequential damages, lost profits, or lost data, regardless of the
          foreseeability of those damages) arising out of or in connection
          with your use of the site or any other materials or services
          provided to you by company. This limitation shall apply regardless
          of whether the damages arise out of breach of contract, tort, or any
          other legal theory or form of action.
        </p>
        <h2> 10. AFFILIATED SITES. </h2>
        <p>
          {" "}
          Company may choose to link the Internet websites of partners and
          affiliates to the Site. Company has no control over, and no
          liability for any third party websites or materials and makes no
          guarantees about the accuracy, currency, content, or quality of the
          information provided by such sites, and Company assumes no
          responsibility for unintended, objectionable, inaccurate,
          misleading, or unlawful content that may reside on those sites.
          Similarly, from time to time in connection with your use of the
          Site, you may have access to content items (including, but not
          limited to, websites) that are owned by third parties. You
          acknowledge and agree that Company makes no guarantees about, and
          assumes no responsibility for, the accuracy, currency, content, or
          quality of this third party content.
        </p>
        <h2> 11. PROHIBITED USES.</h2>
        <p>
          {" "}
          Company imposes certain restrictions on your permissible use of the
          Site and the Service. You are prohibited from violating or
          attempting to violate any security features of the Site or Service,
          including, without limitation, (a) accessing content or data not
          intended for you, or logging onto a server or account that you are
          not authorized to access; (b) attempting to probe, scan, or test the
          vulnerability of the Service, the Site, or any associated system or
          network, or to breach security or authentication measures without
          proper authorization; (c) interfering or attempting to interfere
          with service to any user, host, or network, including, without
          limitation, by means of submitting a virus to the Site or Service,
          overloading, “flooding,” “spamming,” “mail bombing,” or “crashing;”
          (d) using the Site or Service to send unsolicited e-mail, including,
          without limitation, promotions, or advertisements for products or
          services; (e) forging any TCP/IP packet header or any part of the
          header information in any e-mail or in any posting using the
          Service; or (f) attempting to modify, reverse-engineer, decompile,
          disassemble, or otherwise reduce or attempt to reduce to a
          human-perceivable form any of the source code used by Company in
          providing the Site or Service. Any violation of system or network
          security may subject you to civil and/or criminal liability.{" "}
        </p>
        <h2> 12. INDEMNITY.</h2>
        <p>
          {" "}
          You agree to indemnify Company for certain of your acts and
          omissions. You agree to indemnify, defend, and hold harmless
          Company, its affiliates, officers, directors, employees,
          consultants, agents, and representatives from any and all third
          party claims, losses, liability, damages, and/or costs (including
          reasonable attorney fees and costs) arising from your access to or
          use of the Site, your violation of these Terms of Use, or your
          infringement, or infringement by any other user of your account, of
          any intellectual property or other right of any person or entity.
          Company will notify you promptly of any such claim, loss, liability,
          or demand, and will provide you with reasonable assistance, at your
          expense, in defending any such claim, loss, liability, damage, or
          cost.
        </p>
        <h2> 13. COPYRIGHT.</h2>
        <p>
          {" "}
          All contents of Site or Service are: Copyright © 2023 Remote Studio
          Inc. All rights reserved.
        </p>
        <h2> 14. GOVERNING LAW.</h2>
        <p>
          {" "}
          These Terms of Use shall be construed in accordance with and
          governed by the laws of the United States and the State of
          California, without reference to their rules regarding conflicts of
          law. You hereby irrevocably consent to the exclusive jurisdiction of
          the state or federal courts in Los Angeles, CA, in all disputes
          arising out of or related to the use of the Site or Service.
        </p>
        <h2> 15. SEVERABILITY; WAIVER.</h2>
        <p>
          {" "}
          If, for whatever reason, a court of competent jurisdiction finds any
          term or condition in these Terms of Use to be unenforceable, all
          other terms and conditions will remain unaffected and in full force
          and effect. No waiver of any breach of any provision of these Terms
          of Use shall constitute a waiver of any prior, concurrent, or
          subsequent breach of the same or any other provisions hereof, and no
          waiver shall be effective unless made in writing and signed by an
          authorized representative of the waiving party.
        </p>
        <h2> 16. NO LICENSE.</h2>
        <p>
          {" "}
          Nothing contained on the Site should be understood as granting you a
          license to use any of the trademarks, service marks, or logos owned
          by Company or by any third party.
        </p>
        <h2> 17. CALIFORNIA USE ONLY.</h2>
        <p>
          {" "}
          The Site is controlled and operated by Company from its offices in
          the State of California. Company makes no representation that any of
          the materials or the services to which you have been given access
          are available or appropriate for use in other locations. Your use of
          or access to the Site should not be construed as Company’s
          purposefully availing itself of the benefits or privilege of doing
          business in any state or jurisdiction other than California.
        </p>
        <h2> 18. MODIFICATIONS.</h2>
        <p>
          {" "}
          Company may, in its sole discretion and without prior notice, (a)
          revise these Terms of Use; (b) modify the Site and/or the Service;
          and (c) discontinue the Site and/or Service at any time. Company
          shall post any revision to these Terms of Use to the Site, and the
          revision shall be effective immediately on such posting. You agree
          to review these Terms of Use and other online policies posted on the
          Site periodically to be aware of any revisions. You agree that, by
          continuing to use or access the Site following notice of any
          revision, you shall abide by any such revision.
        </p>
        <h2> 19. ACKNOWLEDGEMENT.</h2>
        <p>
          {" "}
          By using the service or accessing the site, you acknowledge that you
          have read these terms of use and agree to be bound by them.{" "}
        </p>
        <h2> 20. SUPERSEDING AGREEMENT</h2>
        <p>
          {" "}
          In any case where a conflict arises between this agreement and the
          Subscription Agreement, the Subscription Agreement shall supersede
          this agreement.{" "}
        </p>
      </div>
      </div>
     
    )
}
export default TermsComponent