import { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './ContactForms.module.css'
import Dropdown from './dropdown';


const SalesContact = () => {
    const [didSubmit, setDidSubmit] = useState(false)

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
        firstName: Yup.string(),
        lastName: Yup.string(),
        organization: Yup.string().required('Required'),
        help: Yup.string().required('Required'),
        hearAboutUs: Yup.string(),
        jobTitle: Yup.array(),
        //area: Yup.array(),
        orgRole: Yup.array(),
        consent: Yup.boolean().required('Required')
        // Add other fields as needed
    });
    const handlejobTitleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        const currentValues = formik.values.jobTitle;
        if (checked && !currentValues.includes(value)) {
            formik.setFieldValue('jobTitle', [...currentValues, value]);
        } else if (!checked && currentValues.includes(value)) {
            formik.setFieldValue('jobTitle', currentValues.filter(item => item !== value));
        }
    };
    const handleAreaCheckboxChange = (e) => {
        const { value, checked } = e.target;
        const currentValues = formik.values.area;
        if (checked && !currentValues.includes(value)) {
            formik.setFieldValue('area', [...currentValues, value]);
        } else if (!checked && currentValues.includes(value)) {
            formik.setFieldValue('area', currentValues.filter(item => item !== value));
        }
    };
    const handleOrgRoleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        const currentValues = formik.values.orgRole;
        if (checked && !currentValues.includes(value)) {
            formik.setFieldValue('orgRole', [...currentValues, value]);
        } else if (!checked && currentValues.includes(value)) {
            formik.setFieldValue('orgRole', currentValues.filter(item => item !== value));
        }
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            organization: '',
            help: '',
            jobTitle: [],
            //area: [],
            orgRole: [],
            hearAboutUs: '', // for the dropdown
            consent: false, // for the checkbox
        },
        validationSchema: validationSchema,
        onSubmit: async (values, {setTouched}) => {


            setTouched({
                email: true,
                firstName: true,
                lastName: true,
                organization: true,
                // Set other fields as touched here
            });

            const success = await sendFormDataToZapier(values);
            if (success) {
                setDidSubmit(true)
                // Handle successful form submission (e.g., clear form, show success message)
            } else {
                // Handle unsuccessful form submission (e.g., show error message)
            }
        },
    });

    const sendFormDataToZapier = async (formData) => {
        const zapierWebhookUrl = process.env.REACT_APP_ZAPIER_CONTACT_HOOK; // Replace with your actual Zapier webhook URL

        try {
            const response = await fetch(zapierWebhookUrl, {
                method: 'POST',

                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // Handle success
                console.log('Form data sent successfully');
                return true; // Indicate success
            } else {
                // Handle HTTP errors
                console.error('Error sending form data: ', response.status);
                return false; // Indicate failure
            }
        } catch (error) {
            // Handle network errors
            console.error('Network error when sending form data: ', error);
            return false; // Indicate failure
        }
    };


/* https://stage.remotopro.io/store?email=charles.danner@remotopro.io&passwordless=true&
signature=Xe6MvmNkcmeTL2cPtv2w9RK1SVN_OxV7AVZaMx7JDy7el96aU6SMqMk5idnFaB6sDRNKct1zk25Lq_HYlE4GEPwuN9OA53rIhDdtYQiSjeeaQPpvM9_9CSk6lH3O1gHLLCr1QTbPhACSDV-t4Nqhjx78VOWoE9lL8WoQYNR2CoObkp1NX1zMm4TnU1X8y6Uyykl5IykqYyXtrdfgFI0XHaRJMPqxFn8YuRezP3cFKd4WaCf7ldlvWI6NqQQoUfRnXtZKOEY8uMxM3HdNKyAyaGwX5L1GPm3j26EFkMlhl1bJN4s6UcZIpwwQU6ip4fk-02XTNpCDvTnzZd4z_GjIxw
&
origin=eyJwYXlsb2FkIjp7ImVtYWlsIjoiY2hhcmxlcy5kYW5uZXJAcmVtb3RvcHJvLmlvIiwic29sdXRpb24iOiJXRUIiLCJjdXN0b21DbGFpbXMiOltdfSwiZXhwaXJhdGlvblRpbWUiOiIyMDIzLTEyLTI5VDIyOjA0OjQzLjM0NTIzMVoifQ */

    return (
        <div className = {styles.formContainer}>
            {didSubmit ? <h1>Thank you for your interest! We will get back to you as soon as possible</h1> :
                <div style= {{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <h1 className = {styles.contactHeader}> Connect with a Remoto expert</h1>
                    <h3 className = {styles.contactSubHeader}> Tell us about the work you do and we’ll identify the optimal Remoto solution.</h3>

                    <form className={styles.mainForm} onSubmit={formik.handleSubmit}>
                       
                        <label htmlFor="email">Email *</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className={styles.formFieldIn}
                            required
                            placeholder="Type Your Email"
                        />
                        {formik.errors.email && formik.touched.email ? <div className = {styles.formError}>{formik.errors.email}</div> : null}
                 
                        <label htmlFor="firstName">First Name</label>
                        <input
                            id="firstName"
                            name="firstName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                            className={styles.formFieldIn}
                            placeholder='Type Your First Name'
                        />

                        <label htmlFor="lastName">Last Name</label>
                        <input
                            id="lastName"
                            name="lastName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            className={styles.formFieldIn}
                            placeholder='Type Your Last Name'
                        />
                        <label htmlFor="orgainzation">Organization *</label>
                        <input
                            id="organization"
                            name="organization"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={styles.formFieldIn}
                            required
                            placeholder="Type Your Organizaion"

                            value={formik.values.organization}
                        />
                        {formik.errors.organization && formik.touched.organization ? <div className = {styles.formError}>{formik.errors.organization}</div> : null}

                        <h3 style={{ fontSize: '20px', fontWeight: 'normal' }} htmlFor="jobTitle">Your title and/or role (click all that apply)</h3>
<div className={styles.gridContainer} id="jobTitle" onBlur={formik.handleBlur} onChange={formik.handleChange}>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Animator"
                name="jobTitle"
                value="Animator"
                checked={formik.values.jobTitle.includes("Animator")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Animator">Animator</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="AV"
                name="jobTitle"
                value="AV"
                checked={formik.values.jobTitle.includes("AV")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="AV">AV</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Colorist"
                name="jobTitle"
                value="Colorist"
                checked={formik.values.jobTitle.includes("Colorist")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Colorist">Colorist</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Composer"
                name="jobTitle"
                value="Composer"
                checked={formik.values.jobTitle.includes("Composer")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Composer">Composer</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Engineering / R&D / Technical Management"
                name="jobTitle"
                value="Engineering / R&D / Technical Management"
                checked={formik.values.jobTitle.includes("Engineering / R&D / Technical Management")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Engineering / R&D / Technical Management">Engineering / R&D</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Executive"
                name="jobTitle"
                value="Executive"
                checked={formik.values.jobTitle.includes("Executive")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Executive">Executive</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Graphic Designer"
                name="jobTitle"
                value="Graphic Designer"
                checked={formik.values.jobTitle.includes("Graphic Designer")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Graphic Designer">Graphic Designer</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="IT"
                name="jobTitle"
                value="IT"
                checked={formik.values.jobTitle.includes("IT")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="IT">IT</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Journalist"
                name="jobTitle"
                value="Journalist"
                checked={formik.values.jobTitle.includes("Journalist")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Journalist">Journalist</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Music Producer"
                name="jobTitle"
                value="Music Producer"
                checked={formik.values.jobTitle.includes("Music Producer")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Music Producer">Music Producer</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Operations Management"
                name="jobTitle"
                value="Operations Management"
                checked={formik.values.jobTitle.includes("Operations Management")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Operations Management">Operations Management</label>
    </div>
   
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Picture Editor"
                name="jobTitle"
                value="Picture Editor"
                checked={formik.values.jobTitle.includes("Picture Editor")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Picture Editor">Picture Editor</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Post Supervisor"
                name="jobTitle"
                value="Post Supervisor"
                checked={formik.values.jobTitle.includes("Post Supervisor")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Post Supervisor">Post Supervisor</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Sales & Marketing"
                name="jobTitle"
                value="Sales & Marketing"
                checked={formik.values.jobTitle.includes("Sales & Marketing")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Sales & Marketing">Sales & Marketing</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Sound Designer"
                name="jobTitle"
                value="Sound Designer"
                checked={formik.values.jobTitle.includes("Sound Designer")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Sound Designer">Sound Designer</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Sound Engineer"
                name="jobTitle"
                value="Sound Engineer"
                checked={formik.values.jobTitle.includes("Sound Engineer")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Sound Engineer">Sound Engineer</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="System Design/Installation"
                name="jobTitle"
                value="System Design/Installation"
                checked={formik.values.jobTitle.includes("System Design/Installation")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="System Design/Installation">System Design/Installation</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="VFX artist"
                name="jobTitle"
                value="VFX artist"
                checked={formik.values.jobTitle.includes("VFX artist")}
                onChange={handlejobTitleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="VFX artist">VFX artist</label>
    </div>
    <div className={styles.horizontalContainer}>
        
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="OtherArea"
                name="orgRole"
                value="Other"
                checked={formik.values.orgRole.includes("Other")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="OtherArea">Other</label>
    </div>
</div>

                        {/* org Role*/}
                        <h3 style={{ fontSize: '20px', fontWeight: 'normal' }} htmlFor="orgRole">Your organization's primary activity</h3>
<div className={styles.gridContainer} id="orgRole" onBlur={formik.handleBlur} onChange={formik.handleChange}>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Audio Production"
                name="orgRole"
                value="Audio Production"
                checked={formik.values.orgRole.includes("Audio Production")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Audio Production">Audio Production</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Creative Agency"
                name="orgRole"
                value="Creative Agency"
                checked={formik.values.orgRole.includes("Creative Agency")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Creative Agency">Creative Agency</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Developer"
                name="orgRole"
                value="Developer"
                checked={formik.values.orgRole.includes("Developer")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Developer">Developer</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Educational Institution/School"
                name="orgRole"
                value="Educational Institution/School"
                checked={formik.values.orgRole.includes("Educational Institution/School")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Educational Institution/School">Educational Institution/School</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Faith-Based Organization"
                name="orgRole"
                value="Faith-Based Organization"
                checked={formik.values.orgRole.includes("Faith-Based Organization")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Faith-Based Organization">Faith-Based Organization</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Film & TV Distribution"
                name="orgRole"
                value="Film & TV Distribution"
                checked={formik.values.orgRole.includes("Film & TV Distribution")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Film & TV Distribution">Film & TV Distribution</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Film & TV Production"
                name="orgRole"
                value="Film & TV Production"
                checked={formik.values.orgRole.includes("Film & TV Production")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Film & TV Production">Film & TV Production</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Independent Creative/Artist"
                name="orgRole"
                value="Independent Creative/Artist"
                checked={formik.values.orgRole.includes("Independent Creative/Artist")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Independent Creative/Artist">Independent Creative/Artist</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Localization"
                name="orgRole"
                value="Localization"
                checked={formik.values.orgRole.includes("Localization")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Localization">Localization</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Music Publishing & Distribution"
                name="orgRole"
                value="Music Publishing & Distribution"
                checked={formik.values.orgRole.includes("Music Publishing & Distribution")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Music Publishing & Distribution">Music Publishing & Distribution</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="News Organization"
                name="orgRole"
                value="News Organization"
                checked={formik.values.orgRole.includes("News Organization")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="News Organization">News Organization</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Online Content Production"
                name="orgRole"
                value="Online Content Production"
                checked={formik.values.orgRole.includes("Online Content Production")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Online Content Production">Online Content Production</label>
    </div>
   
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Post Production Services"
                name="orgRole"
                value="Post Production Services"
                checked={formik.values.orgRole.includes("Post Production Services")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Post Production Services">Post Production Services</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Recording Services"
                name="orgRole"
                value="Recording Services"
                checked={formik.values.orgRole.includes("Recording Services")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Recording Services">Recording Services</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Rental Company"
                name="orgRole"
                value="Rental Company"
                checked={formik.values.orgRole.includes("Rental Company")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Rental Company">Rental Company</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Systems Integration (AV / IT)"
                name="orgRole"
                value="Systems Integration (AV / IT)"
                checked={formik.values.orgRole.includes("Systems Integration (AV / IT)")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Systems Integration (AV / IT)">Systems Integration (AV / IT)</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="VFX studio"
                name="orgRole"
                value="VFX studio"
                checked={formik.values.orgRole.includes("VFX studio")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="VFX studio">VFX studio</label>
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="Video Production"
                name="orgRole"
                value="Video Production"
                checked={formik.values.orgRole.includes("Video Production")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="Video Production">Video Production</label>
    </div>
    <div className={styles.horizontalContainer}>
        
    </div>
    <div className={styles.horizontalContainer}>
        <label className={styles.customCheckbox}>
            <input
                type="checkbox"
                id="OtherArea"
                name="orgRole"
                value="Other"
                checked={formik.values.orgRole.includes("Other")}
                onChange={() => handleOrgRoleCheckboxChange}
                style={{ width: 'auto' }}
            />
            <span className={styles.checkmark}></span>
        </label>
        <label className={styles.checkboxText} htmlFor="OtherArea">Other</label>
    </div>
</div>

                        


                        <h3 style={{ fontSize: '20px', fontWeight: 'normal' }} htmlFor="hearAboutUs">How did you hear about Remoto?</h3>
                        {/* <select
                            id="hearAboutUs"
                            name="hearAboutUs"
                            onChange={formik.handleChange}
                            value={formik.values.hearAboutUs}
                            className = {styles.formFieldOut}
                        >
                            <option value="Referral">Referral</option>
                            <option value="Social Media">Social Media</option>
                            <option value="Search Engine">Search Engine</option>

                            <option value="Word of Mouth">Word of Mouth</option>
                            <option value="Advertising">Advertising</option>
                            <option value="Other">Other</option>
                        
                        </select> */}
                        <Dropdown
                        options={['Referral', 'Social Media', 'Search Engine', 'Word of Mouth', 'Advertising', 'Other']}
                        value={formik.values.hearAboutUs}
                        onChange={(value) => formik.setFieldValue('hearAboutUs', value)}
                    />
                        <h3 style={{ fontSize: '20px', fontWeight: 'normal' }} htmlFor="help">How can we help? *</h3>
                        <textarea
                            id="help"
                            name="help"
                            onChange={formik.handleChange}
                            value={formik.values.help}
                            className = {styles.formFieldIn}
                            required
                            placeholder='Type Your Message Here'
                        />
                        {formik.errors.help && formik.touched.help ? <div>{formik.errors.help}</div> : null}


                        <p>Remoto respects your privacy. Your personal information will be used for administering your account only. To receive occasional updates from us, please check the box below. You can unsubscribe anytime.
                        </p>
                        <div className={styles.horizontalContainer}>

                            <label className={styles.customCheckbox}>

                                <input
                                    id="consent"
                                    name="consent"
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    checked={formik.values.consent}
                                    style={{ width: 'fit-content', height: 'fit-content'}}
                                />
                                <span className={styles.checkmark}></span>
                            </label>
                            <label htmlFor="consent" style = {{paddingLeft: '10px'}}>
                                Subscribe
                            </label>
                            {/*show an error message if the checkbox is not checked*/}

                          
                        </div>

                        <button className={styles.submitButton} type="submit" disabled={!(formik.isValid && formik.dirty)}>Submit</button>
                    </form>
                </div >}
        </div >
    );

}

export default SalesContact


