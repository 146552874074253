import React, { useEffect, useState } from 'react';
import './VideoCarousel.css';
const videos = [
  {
    id: 1,
    title: "Welcome to RP!",
    url: "https://www.youtube.com/embed/LPF8bVzCEVw?si=rX-eNtIJqP44xLRd",
    description: "Welcome to Remoto Playback!"
  },
  {
    id: 2,
    title: "How to Set Up Remoto Playback's Free Test Drive",
    url: "https://www.youtube.com/embed/nOx73OLdc0k?si=7t1_UdgbxHLWTubL",
    description: "How to Set Up Remoto Playback's Free Test Drive"
  }
];

const VideoCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const videoLength = videos.length;
  
    const goToPrev = () => {
      const newIndex = currentIndex === 0 ? videoLength - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };
  
    const goToNext = () => {
      const newIndex = currentIndex === videoLength - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };
  
    return (
      <div className="carousel-container">
        <div className="video-wrapper">
          {videos.map((video, index) => (
            <iframe
              key={video.id}
              src={video.url}
              style={{ display: index === currentIndex ? 'block' : 'none' }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            ></iframe>
          ))}
        </div>
        <div className="arrows">
          <button onClick={goToPrev}>&lt;</button>
          <button onClick={goToNext}>&gt;</button>
        </div>
        <div className="dots">
          {videos.map((_, index) => (
            <span
              key={index}
              className={index === currentIndex ? 'dot active' : 'dot'}
              onClick={() => setCurrentIndex(index)}
            ></span>
          ))}
        </div>
      </div>
    );
  };
  
  export default VideoCarousel;