import styles from "./HelpComponent.module.css";
import React from "react";
import { useEffect, useState, useRef } from "react";
import SearchBar from "./HelpSearchComponent";
import DanteChatBox from "./DanteAiComponent";
import SupportContact from "../ContactForms/support_contact";
import { useNavigate, useLocation } from 'react-router-dom';

const HelpComponentForm = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const showForm = true;
  const botOpen =  false;


  const navigateToExternalUrl = (url) => {
    window.location.href = url;
  };
  const defaultCopy = [
    {
      tag: "Getting Started",
      text: "Quick start guides on topics including account creation and session setup",
      link: 'https://docs.remotopro.io'
    },
    {
      tag: "FAQ",
      text: "Start here for answers to common questions",
      link: 'https://docs.remotopro.io'
    },
    {
      tag: "Documentation",
      text: "User guides and release notes",
      link: 'https://docs.remotopro.io'
    },

  ];


  const grid = defaultCopy.map((listVal) => {
    return (
      <div className={styles.gridCard}>
        <div className={styles.gridCardChildContainer} onClick={() => { navigateToExternalUrl(listVal.link) }}>
          <h2 className={styles.cardHeader}>{listVal.tag}</h2>
          <div className={styles.cardTextRow}>
            <p className={styles.cardText}>{listVal.text}</p>
            <img
              className={styles.doubleArrow}
              src="./icons/featuresPlaybackPage/DoubleArrow.svg"
            />
          </div>
        </div>
      </div>
    );
  });




  


  function getHeadlineText(botOpen, showForm) {

    if (botOpen) {
      return 'Chat with Remi our customer support AI!'
    }
    if (showForm) {
      return 'Submit a Support Request'
    }



  }


  return (
    <div className={styles.helpComponent}>
      <h1 className={styles.headlineText}>Submit a Support Request</h1>




        <div className={styles.fadeIn}>

          <div id={'supportForm'}> <SupportContact />
          </div>
          

          <div className={styles.hz}>


       

            <div className={styles.supportButton} onClick={() => navigate('/help-center')}>
              {'Back to Help Center'}
            </div>

          </div>

        
      </div>

    </div>
  );
};

export default HelpComponentForm;
