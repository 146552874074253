import { useEffect, useState, useContext } from "react";
import Footer from "../../components/Footer";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useLocation,
    useNavigate
} from "react-router-dom";

import TopBarRemotoWeb from "../../components/TopBarRemotoWeb";
import StoreComponent from "../../components/StoreComponent/store_component";
import LoginComponent from "../../components/AuthComponent/login_component";
import LoginChallengeComponent from "../../components/AuthComponent/login_challenge_component";
import { StoreContext } from "../../context/StoreContext";
import StripeHandoffComponent from "../../components/AuthComponent/stripe_handoff"
import CreateAccountComponent from "../../components/AuthComponent/create_account_component";
import ConfirmAccountComponent from "../../components/AuthComponent/confirm_account_component";
import ResetPasswordComponent from "../../components/AuthComponent/reset_password_component";
import ForgotPasswordComponent from "../../components/AuthComponent/forgot_password_component";
import NotifyModal from "../../modals/NotifyModal";
import styles from "./auth_page.module.css";
import ChangeOtpComponent from "../../components/AuthComponent/change_otp_component";
const AuthPage = () => {
   
    const navigate = useNavigate();
    const { cart, setCart, currentPlan, setCurrentPlan, userAction, setUserAction, userEmail, setUserEmail, env, getEnv } = useContext(StoreContext);
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        if (queryParams.get('email')) {
            setUserEmail(queryParams.get('email'))
        }; // Replace 'paramName' with your parameter key

    }, [location])
    useEffect(()=>{
        if (env == 'unset'){
          getEnv()
        }
      },[])
    return (
        <div style={{ display: 'flex', height: '100vh', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'var(--mine-shaft' }}>
            {/* <button
                className = {styles.backToStore}
                style={{
                    
                }}
                onClick={() => { navigate('/store') }}
            >
                <img
                    className= {styles.backArrow}
                 
                    src={'/icons/leftArrow.png'}
                    alt="Back" // Accessibility improvement
                />
               
            </button> */}

            {((userAction == 'LOGIN')) && <LoginComponent />}
            {userAction == 'LOGOUT_FROM_OTHER_DEVICES' && <LoginChallengeComponent />}
            {(!userAction || userAction == 'STRIPE_HANDOFF') && <StripeHandoffComponent />}
            {userAction == 'CREATE_ACCOUNT' && <CreateAccountComponent />}
            {userAction == 'CONFIRM_ACCOUNT' && <ConfirmAccountComponent />}
            {userAction == 'RESET_PASSWORD' && <ResetPasswordComponent />}
            {userAction == 'FORGOT_PASSWORD' && <ForgotPasswordComponent />}
            {userAction == 'CHANGE_OT_PASSWORD' && <ChangeOtpComponent />}
            {/* <div style = {{width: '500px', padding: '0px', justifyContent: 'center', alignItems: 'center', gap: '10px'}}> 
                <p style ={{color: "var(--manatee)"}}> Not sure about your network settings before streaming? </p> <a> Run Network Test here.</a> 
            </div> */}
            <NotifyModal />
        </div>
    )
}

export default AuthPage