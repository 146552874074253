import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../context/StoreContext";
import TermsComponent from "../../pages/terms_page/terms_component";
import styles from "./auth_flow.module.css";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';


const ResetPasswordComponent = () => {
  const { env, getEnv, cart, setCart, currentPlan, setCurrentPlan, userToken, setUserToken, userAction, setUserAction, setNotifyObject, setUserEmail, userEmail } = useContext(StoreContext);

  const navigate = useNavigate();
  const [viewTerms, setViewTerms] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleConfirmPasswordVisiblity = () => {
    setShowConfirmPassword(showConfirmPassword => !showConfirmPassword);
  };
  const togglePasswordVisiblity = () => {
    setShowPassword(showPassword => !showPassword);
  };

  const accountSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    confirmationCode: Yup.string()
      .required('Please enter your confirmation code'),
    newPassword: Yup.string()
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
      .matches(/[^A-Za-z0-9]/, 'Password must contain at least one special character.')
      .matches(/[0-9]/, 'Password must contain at least one number.')
      .required('Password is required'),

  });


  const resetPassword = async (values) => {
    console.log('started')
    const { email, confirmationCode, newPassword } = values;
    const url = `https://api.${env}remotopro.io/v1/auth/password`;
    const requestOptions = {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'SolutionContext': 'WEB',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        confirmationCode: confirmationCode,
        newPassword: newPassword,
        solution: 'WEB'
      })
    };

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      console.log(JSON.stringify(response))
      if (!response.ok) {
        setNotifyObject({ type: 'error', message: data.code.replace(/_/g, ' '), code: data.status })
        setUserAction('LOGIN')
        throw new Error(`HTTP error! status: ${response.status}`);
      }


      setUserAction('LOGIN')
      setUserEmail(email)
      setNotifyObject({ type: 'success', message: 'password updated', code: '' })
      // Handle the response data as needed

    } catch (error) {

      console.error('There was an error!', error);
    }
  };


  return (
    <div className={styles.mainContainer}>
      <div className={styles.loginCard}>
        <img className={styles.logoImage} src={'Remoto PB 1 line.png'} />
        <h1 style = {{fontSize: '24px'}}> Forgot Password</h1>
          <p style = {{ textAlign: 'center'}}> We have sent a password reset code by email {userEmail?`to ${userEmail}`:''}. Enter it below to reset your password</p>
        <Formik
          initialValues={{ email: userEmail || '', confirmationCode: '', newPassword: '' }}
          validationSchema={accountSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await resetPassword(values);
            setSubmitting(false);
      
          }}
        >
          {({ errors, touched }) => (
            <Form className={styles.formContainer}>
              <label className={styles.inputLabel} htmlFor="email">Email</label>
              <Field name="email" type="text" className={styles.inputContainer} />
              {errors.email && touched.email ? <div className={styles.errorText}>{errors.email}</div> : null}

              <label className={styles.inputLabel} htmlFor="confirmationCode">Confirmation Code</label>
              <Field name="confirmationCode" type="text" className={styles.inputContainer} />
              {errors.confirmationCode && touched.confirmationCode ? <div className={styles.errorText}>{errors.confirmationCode}</div> : null}

              <label className={styles.inputLabel} htmlFor="newPassword">New Password</label>
              <div className={styles.pwContainer}>

                <Field name="newPassword" type={showPassword ? "text" : "password"} className={styles.inputContainer} />
                <div className={styles.showHidePW} type="button" onClick={togglePasswordVisiblity}>
                  {showPassword ?
                    <img className={styles.showPwIcon} src={'/ic_type=showPass_XXL_, state=default.svg'} /> :
                    <img className={styles.showPwIcon} src={'/ic_type=showPass_XXL_, state=disabled.svg'} />}
                </div>
                {errors.newPassword && touched.newPassword ? <div className={styles.errorText}>{errors.newPassword}
                </div> : null}



              </div>
              <div className = {styles.hzLabelContainer} style = {{ justifyContent: 'flex-end', gap: '20px'}}> 
              <button type= "button" className = {styles.cancelButton} onClick = {()=> setUserAction('LOGIN')}> Return to Sign In</button>
              <button type="submit" className={styles.actionButton}>Reset Password</button>
                </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
export default ResetPasswordComponent
