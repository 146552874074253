import React, { useState } from 'react';
import styles from './RadialMenu.module.css'; // Importing CSS for styling

function RadialMenu() {
    const [active, setActive] = useState(null); // State to handle active button

    const buttons = [
        { id: 1, label: "Button 1" },
        { id: 2, label: "Button 2" },
        { id: 3, label: "Button 3" },
        { id: 4, label: "Button 4" },
        { id: 5, label: "Button 5" }
    ];

    return (
        <div className={styles.radialMenu}>
            {buttons.map((button, index) => (
                <div
                    key={button.id}
                    className={styles.slice}
                    style={{ transform: `rotate(${index * (360 / buttons.length)}deg)` }}
                    onClick={() => setActive(button.id)}
                >
                <p style = {{textColor: 'white'}}>  {button.label}</p>

                   
                </div>
            ))}
        </div>
    );
}

export default RadialMenu;
