import { FunctionComponent, useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopBarRemotoWeb from "../../components/TopBarRemotoWeb";
import Footer from "../../components/Footer";
import { DataStore } from "@aws-amplify/datastore";
import { FormResponses2 } from "../../models";
import styles from "./contact.module.css";
import ContactForm2 from "../../ui-components/ContactForm2";
import SalesContact from "../../components/ContactForms/sales_contact";

const ContactPage = () => {
  const navigate = useNavigate();

  const handlePolicy = useCallback(() => {
    navigate("/policy");
  }, []);

  return (
    <div className={styles.outerContainer}>
      <TopBarRemotoWeb />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: 'scroll',
          height: 'auto',
          width: '100vw',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginTop: '75px'
        }}
    
      >
      
        <SalesContact/>
        <div className={styles.innerContainer}>
        <p>
         
            If you need technical support with a
            
            product, please click
            <span style={{ fontSize: "12pt", color: "var(--orange)", cursor: "pointer"}}
            onClick={() => {navigate('/help-center')}} >
              {" "}
              here.
              {" "}
            </span>
            </p>
          
         </div>
         <Footer />
      </div>
    
    </div>
  );
};

export default ContactPage;
