import {
  Routes,
  Route,
  useNavigationType,
  useLocation
} from "react-router-dom";
import PortalUIUXCalendlyLandin from "./pages/portalUIUXCalendlyLandin";
import LandingPage from "./pages/landing_page";
import RedirectPage from "./pages/redirect_page";
import { useEffect, useState } from "react";
import RMTWebsite from "./pages/rmtWebsite";
import ContactPage from "./pages/contact";
import HelpPage from "./pages/help_page/help_page";
import HelpFormPage from "./pages/help_page/help_form_page";
import HelpBotPage from "./pages/help_page/help_bot_page";
import StorePage from "./pages/store_page";
import { StoreProvider } from "./context/StoreContext";
import AuthPage from "./pages/auth_page";
import ConnectionTestPage from "./pages/connection_test";
import LegalPage from "./pages/legal_page";
import PlaybackZero from "./pages/playbackZero";
import CareersPage from "./pages/careers_page";
import DownloadAllPage from "./pages/download_all";
import CookieConsent from "react-cookie-consent";
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';
import ResourcesPage from "./pages/resources_page";
import styles from "./App.module.css";

const initializeGTM = () => {
  const tagManagerArgs = {
    gtmId: 'GTM-TPDG9XHZ' // Replace with your GTM ID
  };
  TagManager.initialize(tagManagerArgs);
};
declare global {
  interface Window {
    dataLayer: any[];
  }
}

const trackPageView = (url: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'pageview',
    page: url,
  });
};
function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    const consent = Cookies.get('userConsent');
    if (consent) {
      setConsentGiven(true);
      initializeGTM();
    }
  }, []);
  useEffect(() => {
    if (consentGiven) {
      trackPageView(pathname);
    }
  }, [pathname, consentGiven]);

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    const currentUrl = window.location.href;
    console.log("url", currentUrl);

    
  }, []);

  const handleAcceptCookies = () => {
    Cookies.set('userConsent', 'true', { expires: 365 });
    setConsentGiven(true);
    initializeGTM();
  };

  return (
    <div className = {styles.main} style={{ background: '#343139' }}>
      <StoreProvider>
        <Routes>
          <Route path="/" element={<RMTWebsite />} />
          <Route path="/playback" element={<PlaybackZero />} />
          <Route path="/download" element={<LandingPage />} />
          <Route path="/redirect" element={<RedirectPage />} />
          <Route path="/demo" element={<PortalUIUXCalendlyLandin />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/help-center' element={<HelpPage />} />
          <Route path='/support-form' element={<HelpFormPage />} />
          <Route path='/support-bot' element={<HelpBotPage />} />
          <Route path='/legal' element={<LegalPage />} />
          <Route path='/resources' element={<ResourcesPage />} />

          <Route path='/test' element={<ConnectionTestPage />} />
          <Route path='/store' element={<StorePage />} />
          <Route path='/auth' element={<AuthPage />} />
          <Route path='/careers' element={<CareersPage />} />
          <Route path='/download_all' element={<DownloadAllPage />} />
        </Routes>
      </StoreProvider>

      <CookieConsent
        location="center"
        buttonText="Accept All"
        cookieName="userConsent"
        style={{fontSize: '24px', background: "#313139", alignItems: 'center', height: '200px', padding: '25px',  justifyContent: 'center', border: '1px solid #48485c', borderRadius: '10px' }}
        buttonStyle={{ color: "#48485c", borderRadius: '10px', backgroundColor: '#2DBF81', fontSize: "20px", width: '200px', height: '50px', }}
        expires={365}
        onAccept={handleAcceptCookies}
        enableDeclineButton
        declineButtonText="Only Required"
        declineButtonStyle={{ color: "#ddd8d8",  borderRadius: '10px', fontSize: "20px", width: '200px', height: '50px' }}
        overlay
        onDecline={() => {
          console.log('USER DECLINED GA');
        }}
      >
       This website utilizes cookies to enable essential site functionality, analytics and personlization. 
       <br/>
        <span style={{ fontSize: "14px" }}>To learn more view the following links <a style = {{textDecoration: 'underline'}} href = '/documents?doc=white_paper'> security</a> and <a style = {{textDecoration: 'underline'}} href = '/documents?doc=privacy_policy'> privacy</a> </span>
      </CookieConsent>
    </div>
  );
}

export default App;
