import { useEffect, useState, useContext } from "react";
import styles from "./dropdown.module.css";

const Dropdown = ({ onChange, options, value }) => {
  const [selectedOption, setSelectedOption] = useState(value);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);  // Close the dropdown after selection
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownHeader} onClick={toggleDropdown}>
        {selectedOption || "Select an option"}
        <span className={styles.dropdownArrow}>{isOpen ? '▲' : '▼'}</span>
      </div>
      {isOpen && (
        <div className={styles.dropdownList}>
          {options.map((option) => (
            <div
              className={styles.dropdownItem}
              key={option}
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
