import Footer from "../../components/Footer";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";
import styles from "./store_page.module.css";
import { useEffect, useState } from "react";
import TopBarRemotoWeb from "../../components/TopBarRemotoWeb";
import StoreComponent from "../../components/StoreComponent/store_component";

const StorePage = () => {
  
  return (
    <div style = {{
      height: '100vh', overflow: 'hidden'
    }}> 
    <TopBarRemotoWeb/>
    <div style = {{overflowY: 'scroll', height: '100vh'}}> 
    <div 
      className = {styles.contentContainer}
>
     
     
      <StoreComponent/>
     
      
      


    </div>
     <Footer/>
     </div> 
     </div> 
  );
};

export default StorePage;


