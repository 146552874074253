// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { TestModel, FormResponses2 } = initSchema(schema);

export {
  TestModel,
  FormResponses2
};