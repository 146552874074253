import styles from "./FeaturesComponent.module.css";
import React from "react";
import { useEffect, useState } from "react";
import { featureItems } from "../../constants";
import RadialMenu from "./RadialMenu";
const FeaturesComponent = (props) => {
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedList, setSelectedList] = useState(null);

  const bulletPoints = {
    "Highest quality video live streams": [
      "Up to 4K at 60fps",
      "Supports streaming files using multiple video codecs including AV1",
      "Source video feeds can be software based, like NDI or local screen capture, or hardware based, like SDI or HDMI using Blackmagic or AJA",
      "Showcase your work from your favorite creative software like Pro Tools, Final Cut, Avid Media Composer, DaVinici Resolve, Maya, Flame, and Adobe Creative Cloud apps like Premiere Pro and After Effects",
      "Synchronized playback controls make sure everyone sees the same content with ultra-low latency",
    ],
    "High quality audio": [
      "Live stream frame-accurate sound editing, mixing and music composition sessions",
      "Supports compressed or uncompressed formats, so you can inject a WAV file and Remoto will encode it on the fly",
      "Full spectrum, multi-channel 5.1 or 7.1 surround sound, or up to 20 audio discrete channels to enable creation of Dolby Atmos content",
      "Ultra-low sub-second latency (sub 500ms)",
    ],
    "Powerful collaboration features": [
      "Built-in voice and video chat; no need to manage a separate video call",
      "Time-coded comments can be exported as a track on a timeline",
    ],
  };
  function handleClick(selectedFeat, selectedVal) {
    if (selectedFeat == selectedFeature) {
      setIsVisible(false);
      setSelectedFeature(null);
      setSelectedList(null);
    } else {
      setSelectedFeature(selectedFeat);
      setSelectedList(selectedVal);
      setIsVisible(true);
    }
    const element = document.getElementById(props.target);
    setTimeout(() => {
      const element = document.getElementById(props.target);
      if (element) {
        const elementHeight = element.clientHeight;
        const windowHeight = window.innerHeight;
        const scrollPosition =
          element.offsetTop - windowHeight + elementHeight / 2;
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 200);
  }

  

  const toMap = featureItems.slice(props.startVal, props.endVal);

  const mapCards = Object.entries(toMap).map(([key, value]) => (
    <button
      className={`${styles.square} ${
        selectedFeature === value.title ? styles.selected : ""
      }`}
      onClick={() => handleClick(value.title, value.text)}
      key={key}
    >
      <img
        className={styles.isolationModeIcon1}
        alt={value.imageAlt}
        src={value.imagePath}
      />
      <h1 className={styles.cardTitleText}>{value.title}</h1>
    </button>
  ));

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    
    <div className={styles.featuresMacro}>
    {/* <RadialMenu /> */}
      <div className={styles.featuresGroup}>{mapCards}</div>
      <div id={props.target} className={styles.scrollTarget} />
      {selectedFeature !== null && (
        <div className={selectedFeature?styles.selectedContainer:styles.hiddenContainer}>
         <div className = {styles.featureTextContainer} >
          <h1 className={styles.selectedFeatureText}>{selectedFeature}</h1>

          </div>

          <ul className = {styles.featureList}>
          {selectedFeature === 'Integral, Comprehensive Security' && 
          <div>
            
            {selectedFeature === 'Integral, Comprehensive Security' && 
          <h3 className={styles.securityText}>Remoto Playback is MPA compliant, supporting the security standards adopted by all major studios and networks.
            </h3> }
            <h3 className = {styles.securityText}>
              Security features include:
              </h3>
              </div>
              }
  <div style = {{paddingLeft: '20px'}}>
            {selectedList.map((value) => {
              console.log(value);
              return <li className={styles.listText}> {value} </li>;
            })}
            </div>
             {selectedFeature === 'Integral, Comprehensive Security' && 
          <div>
            
           
            <h2 style = {{textAlign: 'left'}}>
            For additional details on security, check out our <a href = "/documents?doc=white_paper"> white paper</a> or <a href = "/contact"> contact us</a> to arrange a meeting with our technical staff.              </h2>
              </div>
              }
          </ul>
        </div>
      )}
    </div>
  );
};

export default FeaturesComponent;

/* 

 <div className={styles.featuresGroup}>
          <b className={styles.features}>Features</b>
          <div className={styles.line1FeatParent}>
            <div className={styles.line1Feat}>
              <button className={styles.square1}>
                <div className={styles.icStatusWrapper}>
                  <img
                    className={styles.icStatusIcon}
                    alt=""
                    src="/icons/commented/ic-status1.svg"
                  />
                </div>
                <div className={styles.isolationModeWrapper}>
                  <img
                    className={styles.isolationModeIcon}
                    alt=""
                    src="/isolation-mode1.svg"
                  />
                </div>
                <div className={styles.text1Wrapper}>
                  <div className={styles.text1}>
                    {" "}
                    Highest quality video live streams
                  </div>
                </div>
              </button>
              <div className={styles.square2}>
                <div className={styles.icStatusWrapper}>
                  <img
                    className={styles.icStatusIcon1}
                    alt=""
                    src="/icons/commented/ic-status2.svg"
                  />
                </div>
                <div className={styles.isolationModeWrapper}>
                  <img
                    className={styles.isolationModeIcon1}
                    alt=""
                    src="/isolation-mode2.svg"
                  />
                </div>
                <div className={styles.text2Wrapper}>
                  <div className={styles.text2}>High quality audio</div>
                </div>
              </div>
              <button className={styles.square3}>
                <div className={styles.icStatusWrapper}>
                  <img
                    className={styles.icStatusIcon2}
                    alt=""
                    src="/icons/commented/ic-status3.svg"
                  />
                </div>
                <div className={styles.isolationModeWrapper}>
                  <img
                    className={styles.isolationModeIcon2}
                    alt=""
                    src="/isolation-mode3.svg"
                  />
                </div>
                <div className={styles.text3Wrapper}>
                  <div className={styles.text1}>
                    Powerful collaboration features
                  </div>
                </div>
              </button>
            </div>
            <div className={styles.line1Feat}>
              <div className={styles.square4}>
                <div className={styles.icStatusWrapper}>
                  <img
                    className={styles.icStatusIcon}
                    alt=""
                    src="/icons/commented/ic-status1.svg"
                  />
                </div>
                <div className={styles.isolationModeWrapper}>
                  <img
                    className={styles.isolationModeIcon}
                    alt=""
                    src="/isolation-mode1.svg"
                  />
                </div>
                <div className={styles.text1Wrapper}>
                  <div className={styles.text2}>Streamlined for speed</div>
                </div>
              </div>
              <div className={styles.square5}>
                <div className={styles.icStatusWrapper}>
                  <img
                    className={styles.icStatusIcon1}
                    alt=""
                    src="/icons/commented/ic-status2.svg"
                  />
                </div>
                <div className={styles.isolationModeWrapper}>
                  <img
                    className={styles.isolationModeIcon1}
                    alt=""
                    src="/isolation-mode2.svg"
                  />
                </div>
                <div className={styles.text2Wrapper}>
                  <div className={styles.text2}>
                    Integral, comprehensive security
                  </div>
                </div>
              </div>
              <div className={styles.square6}>
                <div className={styles.icStatusWrapper}>
                  <img
                    className={styles.icStatusIcon1}
                    alt=""
                    src="/icons/commented/ic-status2.svg"
                  />
                </div>
                <div className={styles.isolationModeWrapper}>
                  <img
                    className={styles.isolationModeIcon1}
                    alt=""
                    src="/isolation-mode2.svg"
                  />
                </div>
                <div className={styles.text2Wrapper}>
                  <div
                    className={styles.text2}
                  >{`Powerful Integrations & Perks`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
*/
