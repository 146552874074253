import Footer from "../../components/Footer";
import styles from "./help_page.module.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";

import { useEffect, useState } from "react";
import TopBarRemotoWeb from "../../components/TopBarRemotoWeb";
import HelpComponent from "../../components/HelpComponent/HelpComponent";
import SupportContact from "../../components/ContactForms/support_contact";

const HelpPage = () => {

  useEffect(() => {}, []);

  return (
  
    <div className = {styles.helpPage} >
      <TopBarRemotoWeb></TopBarRemotoWeb>
      <div>
      <HelpComponent></HelpComponent>
       <Footer />
       </div> 
    </div>
   
  );
};

export default HelpPage;

