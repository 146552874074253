import { useState, useEffect } from "react";
import styles from "./Store.module.css";
import React, { useContext } from 'react';
import { StoreContext } from "../../context/StoreContext";
import { useNavigate, useLocation } from 'react-router-dom';

const CardTemplate = ({ params }) => {
    const {env, getEnv, challengeId, setChallengeId, cart, setCart, currentPlan, setCurrentPlan, userToken, setUserToken } = useContext(StoreContext);
    const navigate = useNavigate(); 
    const getIndexbyProductId = (products, priceId) => {
        const index = products.findIndex(product => product.priceId === priceId);
        console.log("###4", index)
        return index; // 

    }


    const [selected, setSelected] = useState((getIndexbyProductId(params, cart) != -1) ? getIndexbyProductId(params, cart) : 0);
    const [expanded, setExpanded] = useState(params[selected].type == 'subscription' ? false : true);


    function inCart(priceId) {
        return cart === priceId
    }
    function renderLabel(label) {
        if (!label){
            return null;
        }
        else {
            let temp_array = label.split(' ')
            let first = temp_array[0]
            let second = temp_array[1]
            let caps = first.toUpperCase()
            return caps + " " + second

        }
    }
    const selector = params.map((item, index) => {

        const isSelected = index === selected
        let arr = []
        console.log("421", params)
        
        return (
            
            <p className={`${isSelected ? styles.selectorOn : styles.selectorOff}`} 
            onClick={() => { 
                if(!params.map(item => item.priceId).includes(currentPlan) || item.priceId == currentPlan){
                setSelected(index) 
            }
            }}> {renderLabel(item.label)} </p>
        )
    })

    function formatSessionHours(input) {
        // Check if the number is an integer
        if (Math.floor(input) === input) {
          return input;
        } else {
          // Return the number with two decimal places
          return input.toFixed(2);
        }
      }

    function renderDetails(details) {
        // if (details.includes('(RP)')) {
        //     return details.split('(RP)')
        // }
        // console.log("details", details)
        // let details_array = details.split('\n')
      
        // if (details_array.length == 1) {
        //     return details
        // }
        // else {
        //     return (details_array.map((item) => {
        //         return (
        //             <> {item} <br /> </>
        //         )
        //     }))
        // }
        // if last character of details string is a period
        if (details && details[details.length - 1] === '.') {
            return details
        }
        else{
            return (`${details}.`)
        }
    }

    //THIS FUNCTION MODIFIES THE PRODUCT NAME THAT IS DISPLAYED ON THE CARD
    function checkName(name){
        if (name == 'Pro'){
            return ('PRO plan')
        }
        if (name.includes('Plus')){
            return ('PLUS plans')
        }
        if (name.includes('Ultra') && !name.includes('HD')){
            return ('PEAK plans')
        }
        if (name.includes('Peak') && !name.includes('HD')){
            return ('PEAK plans')
        }
        if (name == 'HD'){
            return ('HD Session')
        }
        // if (name == 'UHD Session'){
        //     return ('Ultra HD Session')
        // }   
        else return (name)
    }

    function isSubscription(priceId) {
        const subscriptionIds = [
            process.env.REACT_APP_SUBSCRIPTION_PRO,
            process.env.REACT_APP_SUBSCRIPTION_P_GUESTS,
            process.env.REACT_APP_SUBSCRIPTION_P_TIME,
            process.env.REACT_APP_SUBSCRIPTION_U_GUESTS,
            process.env.REACT_APP_SUBSCRIPTION_U_TIME,
            process.env.REACT_APP_SUBSCRIPTION_U_BOTH
        ];
    
        return subscriptionIds.includes(priceId);
    }

    //a function that triggers a mailto to sales@remotopro.io with a subject line "Custom Session Inquiry"
    function customSessionInquiry(){
        window.location.href = 'mailto:sales@remotopro.io?subject=Custom Session Inquiry';
    }

    function renderGridItem(icon, label, value) {
        if (label === "Audio Channels" && value !== "eight") {
            return (
                <div className={` ${styles.listGridContainer} ${styles.promoLabel}`}>
                    <img className={styles.listGridIcon} src={icon} />
                    <p className={styles.listGridItem}>{label}</p>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', margin: '0', padding: '0', alignItems: 'center', justifyContent: 'flex-start' }}>
                       
                        <p className={`${styles.listGridItem}`}>  16 </p>
                        <div className = {styles.promoTextBannerSm}> Launch Deal</div> 
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className={` ${styles.listGridContainer} ${styles.promoLabel}`}>
                    <img className={styles.listGridIcon} src={icon} />
                    <p className={styles.listGridItem}>{label}</p>
                    <p className={styles.listGridItem}>{(value === "eight"?'8':value)}</p>

                </div>
            )
        }
    }
    return (
        <div >
            {params[selected].isActive && <div>
                <div className={styles.card}>
                    {/*{(params[selected].priceId == cart) && <div className={styles.cardDeselectButton} onClick={() => setCart('')}> clear </div>}*/}
                    <div className={styles.priceContainer}>
                        <div className = {styles.hzContainer}>
                        <h2 className={styles.productLabel}>{
                        
                        checkName(params[selected].name)
                        
                        }</h2>
                        {params[selected].name.includes('Plus') && <div className = {styles.promoTextBannerBlue}> ★ Most Popular</div>}
                        </div> 
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}}>
                            <h1 className={params[selected].price == 'dynamic pricing' ? styles.mediumPrice : styles.bigPrice}> {(params[selected].priceId == 'BYO') ? '' : '$'}{params[selected].price} </h1>
                            <p className={styles.priceLabel}> {isSubscription(params[selected].priceId) ? 'per month' : ''}</p>

                        </div>
                    </div>
                    <div className={styles.detailsContainer}>
                        <p className={styles.detailsText}>{renderDetails(params[selected].details)}</p>
                        <div style = {{maxHeight: '410px'}}>
                            {!params[selected].name.includes('One-time') && <div className={styles.subSelectorContainer}>
                                
                                {params[1] && selector}
                                {params[selected].name.includes('Pro') && <div style = {{height: '50px'}}> </div>}
                            </div>}
                           
                            <div className={styles.cardSpecList}>
                                <div className={`${params[1] ? styles.cardSpecItems : styles.cardSpecOff}`}>
                                    <div className={` ${styles.listGridContainer} ${styles.promoLabel}`}>
                                        <img className={styles.listGridIcon} src='./icstatus.png' />
                                        <p className={`${styles.listGridItem} `}> {(params[selected].type != 'subscription') ? 'Session Length' : 'Plan Hours'}</p>
                                        {params[selected.type] != 'subscription' && <p className={`${styles.listGridItem} `}>{`${formatSessionHours(params[selected].consumeTime)} ${(params[selected].type != 'subscription')?(`hour${params[selected].consumeTime >1?'s':''}`):''}`} </p>}
                                       
                                    </div>
                                    <div className={` ${styles.listGridContainer} ${styles.promoLabel}`}>
                                        <img className={styles.listGridIcon} src='./icstatus.png' />
                                        <p className={`${styles.listGridItem} `}>Guests</p>
                                        <p className={`${styles.listGridItem}`}>{params[selected].guestMax}</p>
                                    </div>
                                    {params[selected].type !== 'subscription' && renderGridItem('./icstatus.png', 'Resolution', (params[selected].priceId !== process.env.REACT_APP_ONE_TIME_UHD && params[selected].priceId !== "BYO")?'HD':'UHD')}



                                </div>

                                {params[selected].name.includes('Pro') && <div style = {{height: '20px'}}> </div>}

                                {(expanded || params[selected].name.includes('One-time'))
                                 &&
                                 <div style={{ padding: "0px 0px 0px 10px" }}>

                                    {params[selected].type === 'subscription' && renderGridItem('./icstatus.png', 'Resolution', (params[selected].priceId !== process.env.REACT_APP_ONE_TIME_UHD && params[selected].priceId !== "BYO")?'HD':'UHD')}

                                    {params[selected].priceId !== process.env.REACT_APP_FREE?renderGridItem('./icstatus.png', 'Audio Channels', params[selected].audioChannels):renderGridItem('./icstatus.png', 'Audio Channels', 'eight')}
                                   
                                    {renderGridItem('./icstatus.png', 'Chroma', (params[selected].priceId !== process.env.REACT_APP_ONE_TIME_UHD)?'4:2:0':params[selected].chroma)}
                                    {renderGridItem('./icstatus.png', 'Frame Rate', params[selected].fps)}







                                </div>}
                               
                                {!params[selected].name.includes('One-time') && <div className={styles.detailsButton} onClick={() => { setExpanded(!expanded) }}> {expanded ? 'less' : 'more'}</div>}
                                {(currentPlan == params[selected].priceId) && <button className={`${styles.buttonBase} ${styles.buttonCurrent}`}> Current Plan </button>}

                                {(currentPlan != params[selected].priceId) && <button className={`${styles.buttonReg} ${styles.buttonBase}`} onClick={() => {setCart(params[selected].priceId), navigate('/auth')}}> {'Purchase'} </button>}


                            </div>

                        </div>
                    </div>
                </div>
                {/*inCart(params[selected].priceId) && <div className={styles.purchaseButton} onClick={() => { navigate('/auth') }}> Proceed To Purchase </div>*/}
            </div>}

            {/* IF PRODUCT IS NOT ACTIVE => COMING SOON  */}
            {!params[selected].isActive && <div className={styles.cardComingSoon} >
                <div style = {{height: '100%'}} className={`${inCart(params[selected].priceId) ? styles.cardSelected : styles.card}`}>
                    {/*{(params[selected].priceId == cart) && <div className={styles.cardDeselectButton} onClick={() => setCart('')}> clear </div>}*/}
                    <div className={styles.priceContainer}>
                        <h2 className={styles.productLabel}>{params[selected].name}</h2>

                        <div style={{ display: 'flex', height: '100%', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                            <h1 className={params[selected].priceId =='BYO' ? styles.mediumPrice : styles.bigPrice}> {(params[selected].priceId == 'BYO') ? '' : '$'}{params[selected].price} </h1>
                            <p className={styles.priceLabel}> {isSubscription(params[selected].priceId) || params[selected].priceId == 'BYO' ? '' : 'per month'}</p>

                        </div>
                    </div>
                    <div className={styles.detailsContainer}>

                        <p className={styles.detailsText}>{params[1] ? "choose your upgrade" : renderDetails(params[selected].details)}</p>
                        <div >
                     
                            <div className={styles.cardSpecList}>
                                <div className={`${params[1] ? styles.cardSpecItems : styles.cardSpecOff}`}>
                                    <div className={styles.listGridContainer}>
                                        <img className={styles.listGridIcon} src='./icstatus.png' />
                                        <p className={`${styles.listGridItem}`}> {(params[selected].type != 'subscription') ? 'Session Length' : 'Plan Hours'}</p>
                                        <p className={`${styles.listGridItem}`}>{params[selected].consumeTime} hrs </p>
                                    </div>
                                    <div className={styles.listGridContainer}>
                                        <img className={styles.listGridIcon} src='./icstatus.png' />
                                        <p className={`${styles.listGridItem} `}>Guests</p>
                                        <p className={`${styles.listGridItem}`}>{params[selected].guestMax}</p>
                                    </div>



                                </div>

                               

                                {<div style={{ padding: "0px 0px 0px 10px" }}>


                                    {renderGridItem('./icstatus.png', 'Resolution', params[selected].videoRes)}
                                    {
                                          <div className={` ${styles.listGridContainer} ${styles.promoLabel}`}>
                                          <img className={styles.listGridIcon} src={'./icstatus.png'} />
                                          <p className={styles.listGridItem}>Audio Channels</p>
                                          <div style={{ display: 'flex', flexDirection: 'row', gap: '2px', margin: '0', padding: '0', alignItems: 'center' }}>
                                             
                                              <p className={`${styles.listGridItem}`}>  Up to 16 </p>
                                          </div>
                                      </div>
                                    }
                                    {renderGridItem('./icstatus.png', 'Chroma', params[selected].chroma)}
                                    {renderGridItem('./icstatus.png', 'Frame Rate',  params[selected].fps)}
                                    
                                </div>}
                                <div className = {styles.detailsButton} style = {{height: '19.5px'}}> </div>
                                <button className={styles.buttonReg} onClick = {customSessionInquiry}> Coming Soon </button>


                            </div>

                        </div>
                    </div>
                </div>
                {/*inCart(params[selected].priceId) && <div className={styles.purchaseButton} onClick={() => {  }}> Proceed To Purchase </div>*/}
            </div>}

        </div>

    )
}
export default CardTemplate