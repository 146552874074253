import Footer from "../Footer";
import styles from "./redirect_page_component.module.css";
import { StoreContext } from "../../context/StoreContext";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";

// import BackgroundImage from "../../../public/backgrounds/center_frame.png";

import { useEffect, useState, useContext } from "react";

const RedirectPageComponent = () => {
  const [download_state, set_download_state] = useState(
    "Click here to download"
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [sessionId] = useState(searchParams.get("session_id"));
  const [companyId] = useState(searchParams.get("company_id"));
  const [solution, set_solution] = useState(searchParams.get("solution"));
  const [email_type] = useState(searchParams.get("email_type"));
  const [action, setAction] = useState(searchParams.get("action"));
  const [user_action] = useState(searchParams.get("user_action"));
  const [email] = useState(searchParams.get("email"));
  const [userId] = useState(searchParams.get("user_id"));
  const [bodyCopy, setBodyCopy] = useState("");
  const [result, setResult] = useState(searchParams.get("result"));

  const [arch, set_arch] = useState("");
  const [platform, set_platform] = useState("");
  const [platform_version, set_platform_version] = useState("");
  const [download_links, set_download_links] = useState("");
  const { env, getEnv } = useContext(StoreContext);
  const [origin] = useState(searchParams.get("origin"));
  const [signature] = useState(searchParams.get("signature"));
  const [x86Url, setx86Url] = useState("");
  const [armUrl, setArmUrl] = useState("");
  const [uniUrl, setUniUrl] = useState("");
  var copyDictionary = {};
  /*
  email_type:
  JOIN_SESSION,
  SESSION_UPDATED,
  
  
  SESSION_CANCELED,
  USER_ACCEPTED_INVITATION,
  USER_DECLINED_INVITATION,
  SESSION_REMINDER,
  
  account_verificaiton,
  WELCOME,
  
  USER_LOCKED,
  
  INVITATION_TO_COMPANY,
  */
  const detailsCopy = "You can check more details in the application:";
  const redirectCopy =
    "You will be redirected to the application. Please Wait.";
  const downloadCopy = "The download will begin automatically. Please Wait.";
  const accept_companyCopy = "You have accepted the invitation to the Company";
  const decline_companyCopy = "You have declined the invitation to the Company";


  function getBodyCopy(_email_type) {
    if (_email_type == "company_invitation") {
      return detailsCopy;
    } else if (_email_type == "welcome") {
      return downloadCopy;
    } else if (_email_type == "session_invitation") {
      return redirectCopy;
    }
  }
  function isChrome() {
    return (
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    );
  }

  function osfunction() {
    if (isChrome()) {
      // The code for Chrome
      navigator.userAgentData
        .getHighEntropyValues([
          "architecture",
          "model",
          "platform",
          "platformVersion",
          "fullVersionList",
        ])
        .then((ua) => {
          console.log("Architecture:", ua["architecture"]);
          console.log("Platform:", ua["platform"]);
          console.log("Platform Version:", ua["platformVersion"]);
        })
        .catch((error) => {
          console.error(
            "An error occurred while fetching user agent data:",
            error
          );
        });
    } else {
      console.log("This browser is not Chrome.");
      // Handle other browsers or do nothing
    }
  }

  const fetchDownloadLinks = async (_env) => {
    let env_var = await _env;

    try {
      const response = await fetch(
        `https://api.${env_var}remotopro.io/v1/desktop/download?os=MAC`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
            SolutionContext: "RP",
            // Add any other required headers here
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const sortedByVersionDescending = data.sort((a, b) =>
          b.version.localeCompare(a.version, undefined, { numeric: true })
        );
        const latestVersions = sortedByVersionDescending.slice(0, 2);
        setUniUrl(latestVersions[0].downloadUrl);

        console.log('DOWNLOADJSON', latestVersions)
        
        set_download_links(latestVersions);
        // const arm_key = Object.keys(latestVersions).find((key) => {
        //   return latestVersions[key].arch == "ARM64";
        // });

        // setArmUrl(latestVersions[arm_key].downloadUrl);
        // const x86_key = Object.keys(latestVersions).find((key) => {
        //   return latestVersions[key].arch == "INTEL";
        // });
        // setx86Url(latestVersions[x86_key].downloadUrl);

         
        
        // Process the response data here
      } else {
        // Handle error responses
        console.error("Request failed with status:", response.status);
      }
    } catch (error) {
      // Handle network errors
      console.error("Request failed due to network error:", error);
    }
  };

  ///needs logic for checking version # currently only use one version per arch.
  const getDownloadUrlByArch = (_arch) => {
    if (download_links) {
      const matchingKey = Object.keys(download_links).find((key) => {
        if (_arch == "arm") {
          return download_links[key].arch === "ARM64";
        } else return download_links[key].arch === "X86";
      });

      if (matchingKey) {
        console.log(download_links[matchingKey]);
        return download_links[matchingKey].downloadUrl;
      }
      console.log("none");
      return download_links[0].downloadUrl; // Return null if no match is found
    }
  };

  const handleCompanyInvitation = async () => {
    const url = `https://api.${env}remotopro.io/v1/company/invitation/${user_action}?origin=${origin}&signature=${signature}`;

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const responseData = await response.json();
      console.log(responseData);
      // Process the response as needed
    } catch (error) {
      console.error("Error:", error);
      // Handle the error
    }
  };


  useEffect(() => {
    let _env = getEnv();

    osfunction();
    if (env != 'unset') {
      fetchDownloadLinks(env);
    }

    if (action === "accept_company" || action === "decline_company") {
      handleCompanyInvitation();
      console.log(action);
    }

    if (result == 'stripe_checkout_successful') {

      window.location.href = `com.remotopro.playback://remoto.app/${result}`;
    }
    if (result == 'stripe_checkout_canceled') {
      window.location.href = `https://${env}remotopro.io/store`
    }
    if (result == 'stripe_portal_closed') {
      window.location.href = `com.remotopro.playback://remoto.app/${result}`;
    }
    if (solution) {
      set_solution(solution.toUpperCase());
      console.log(email_type);
      setBodyCopy(getBodyCopy(email_type));

      


      if (action == "join_session_desk" || action == "open_remoto") {
        if (solution == "RP") {
          window.location.href = `com.remotopro.playback://remoto.app/${action}?session_id=${sessionId}&user_email=${email}&user_id=${userId}`;
        }
        if (solution == "RR") {
          window.location.href = `com.remotopro.recording://remoto.app/${action}?session_id=${sessionId}&user_email=${email}&user_id=${userId}`;
        }
      }
      if (email_type == "open_desk" || email_type == 'weekly_digest') {
        if (solution == "RP") {
          window.location.href = `com.remotopro.playback://remoto.app/${action}?user_email=${email}&user_id=${userId}`;
        }
        if (solution == "RR") {
          window.location.href = `com.remotopro.recording://remoto.app/${action}?user_email=${email}&user_id=${userId}`;
        }
      }

      //need to get url programatically
      if (email_type == "remoto_invitation" && download_links) {
        //downloadFile()
      }
      if (email_type == "company_invitation") {
        if (action == "accept_company") {
          console.log("accepted");
        }
        if (action == "decline_company") {
          console.log("declilned");
        }
      }
      if (email_type == "session_invitation" || email_type == 'session_reminder' || email_type == 'session_update') {
        if (action == "join_session_web") {
          
          //check if session is SRT over Dolby
          
          openWebApp(solution, sessionId);
        }
        if (action == "join_session_desk") {
          openApp(solution);
        }
      }
    }
    //add more validations and error logging for email_type + session
  }, [env, solution, email_type, sessionId, bodyCopy, action]);

  function downloadFile() {
    const aElement = document.createElement("a");

    console.log(arch);
    const href = getDownloadUrlByArch(arch);

    aElement.href = href;
    // aElement.setAttribute('href', href);
    aElement.setAttribute("target", "_self");
    set_download_state("downloading");

    aElement.click();
    URL.revokeObjectURL(href);

    set_download_state("Retry Download");
  }

  function downloadArm() {
    const aElement = document.createElement("a");

    console.log(arch);
    const href = armUrl;

    aElement.href = href;
    // aElement.setAttribute('href', href);
    aElement.setAttribute("target", "_self");
    set_download_state("downloading");

    aElement.click();
    URL.revokeObjectURL(href);

    set_download_state("Retry Download");
  }
  function downloadX86() {
    const aElement = document.createElement("a");

    console.log(arch);
    const href = x86Url;

    aElement.href = href;
    // aElement.setAttribute('href', href);
    aElement.setAttribute("target", "_self");
    set_download_state("downloading");

    aElement.click();
    URL.revokeObjectURL(href);

    set_download_state("Retry Download");
  }
  //landing?solution={solution}&email_type={emailType}&email={email}&session_id={sessionId}&company_id={companyId}
  function downloadUni(){
    const aElement = document.createElement("a");

    console.log(arch);
    const href = uniUrl;

    aElement.href = href;
    // aElement.setAttribute('href', href);
    aElement.setAttribute("target", "_self");
    set_download_state("downloading");

    aElement.click();
    URL.revokeObjectURL(href);

    set_download_state("Retry Download");
  }
  function openApp(_solution) {
    if (_solution === "RP" || !_solution) {
      if (action == "join_session_desk") {
        window.location.href = `com.remotopro.playback://remoto.app/${action}?session_id=${sessionId}&user_email=${email}&user_id=${userId}`;
      }
      if (action == "open_desk") {
        window.location.href = `com.remotopro.playback://remoto.app/${action}?user_email=${email}&user_id=${userId}`;
      } else {
        window.location.href = `com.remotopro.playback://remoto.app/${action}?user_email=${email}&user_id=${userId}`;
      }
    }
    ///handle RR Open APP link
  }

  async function getSessionType(_sessionId) {
    try {
      const response = await fetch(`https://api.${env}remotopro.io/v1/playback/sessions/${sessionId}/settings/streaming/source-type`);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        return data === 'SRT_OVER_DOLBY';
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error fetching session type:', error);
      return false;
    }
  }
  //
  async function openWebApp(_solution, _sessionId) {
    if (_solution === "RP" || !_solution) {
      if (action === "join_session_web") {
        try {
          const sessionTypeCheck = await getSessionType(_sessionId);
          console.log(`SessionType: ${sessionTypeCheck}`);
          if (sessionTypeCheck) {
            window.location.href = `https://client.${env}remotopro.io?session_id=${_sessionId}&user_email=${email}`;
          } else {
            setResult('funnel_to_desk')
            setAction("join_session_desk");
          }
        } catch (error) {
          console.error('Error opening web app:', error);
        }
      }
    }
    // Handle RR Open APP link logic here
  }
  function getFullSolutionName(in_sol) {
    if (in_sol == "RP") {
      return "playback";
    }
    if (in_sol == "RR") {
      return "recording";
    }
    if (in_sol == "AP") {
      return "admin";
    }
  }
  function getFullSolutionNameUP(in_sol) {
    if (in_sol == "RP") {
      return "Playback";
    }
    if (in_sol == "RR") {
      return "Recording";
    }
    if (in_sol == "AP") {
      return "Admin";
    }
  }

  function chooseFile(_solution, _email_type, _env) {
    if (_solution === "RP" || !_solution) {
      downloadFile();
    }
    ///include RR installer link
    ///handle "beta", "prod" as email_types
  }
  const root_copy_text =
    "Work together like a pro with our state-of-the-art video collaboration app";

  (copyDictionary["default"] = "Download"),
    (copyDictionary["join_session"] = `Opening Remoto ${getFullSolutionNameUP(
      solution
    )}`);
  copyDictionary["open_desk"] = `Opening Remoto ${getFullSolutionNameUP(solution)}`;

  copyDictionary["welcome"] = `Downloading Remoto ${getFullSolutionNameUP(
    solution
  )}`;
  copyDictionary["accept_company"] = "You have accepted the company invitation";
  copyDictionary["decline_company"] =
    "You have declined the company invitation";
  copyDictionary["join_session_web"] =
    "Redirecting to the Remoto Playback Web Client";

  return (
    <div className={styles.containerBKG}>
      <div className={styles.portaluiuxDownloadLandin}>
        <main className={styles.centerFrame}>
          <div className={styles.contentFrame} id="contentFrame">
            <div className={styles.extraInfo}>
              {bodyCopy}
              <img
                className={styles.logoIcon}
                alt=""
                src="/Remoto PB 1 line.png"
              />
              {(result == 'stripe_checkout_successful')  &&
                <div>
                  <div style={{ height: 'fit-content', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}> <img style={{ width: '30px', height: '30px', margin: '20px'}} src='/icons/Success_Green_REMT.png' /> <div className={styles.resultHeader}> Payment Succesful!</div> </div>
                  <div className={styles.resultMessage}> Download or open the Remoto Playback desktop app.</div>
                </div> 
            }
              {(result == 'stripe_checkout_canceled') && 
                <div>
                  <div style={{ height: 'fit-content', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}> <div className={styles.resultHeader}> Checkout Canceled</div> </div>
                  <div className={styles.resultMessage}> You will be redirected to your desktop application. Please wait</div>
                </div>

              }
               {(result == 'stripe_portal_closed') && 
                <div>
                  <div style={{ height: 'fit-content', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}> <div className={styles.resultHeader}> Checkout Canceled</div> </div>
                  <div className={styles.resultMessage}> You will be redirected to your desktop application. Please wait</div>
                </div>

              }


                {(result == 'funnel_to_desk') && 
                <div>
                  <div style={{ height: 'fit-content', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}> <div className={styles.resultHeader}> The session organizer has restricted access to Dekstop App users</div> </div>
                  <div className={styles.resultMessage}> Please download the App on a MacOS device or contact your host. </div>
                </div>

              }
               {(!result) && 
                <div>
                  <div style={{ height: 'fit-content', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}> <div className={styles.resultHeader}> {copyDictionary[action]} </div> </div>
                  <div className={styles.resultMessage}> Please Wait </div>
                </div>

              }
              {/* <h3></h3> */}
            </div>

            <div style={{ height: 'auto' }}>
            <h3 style = {{fontWeight: '300', fontSize: '36px', margin: '30px', width: '350px', alignSelf: 'center', textAlign:'center'}}>Playback for MacOS</h3> 
              <div className={styles.contentFrame1} id="ContentFrame">

              
              <div className={styles.installerCardMacos}>
                <img
                  className={styles.downloadIcon2}
                  alt=""
                  src={`https://cdn.${env}remotopro.io/web-assets/downloadspage/download-icon-vector.png`}
                  
                />

                <div
                  style={{
                    backgroundColor: "#44444D",
                    width: "100%",
                    height: "1px",
                  }}
                >
                  {" "}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    paddingLeft: ".5vw",
                    paddingRight: ".5vw",
                  }}
                >
                  <h1 className = {styles.listTitle}>Requirements</h1>
                  <ul
                    className = {styles.listElements}
                  >
                    <li>Processor:</li>
                    <p> Intel Core i5 / Apple M1 chip or higher</p>
                    <li>Memory:</li>
                    <p> 4 GB RAM minimum </p>
                    <p> 8 GB RAM or higher recommended</p>
                    <li>Disk Space:</li>
                    <p> 1 GB minimum available</p>
                    <li>Operating System:</li>
                    <p>Big Sur 11.1 or later </p>
                  </ul>
                </div>
                <div
                  style={{
                    backgroundColor: "#44444D",
                    width: "100%",
                    height: "1px",
                  }}
                >
                  {" "}
                </div>
                <div
                  style={{
                    height: "auto",
                    minWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <button className={styles.bticprimary} onClick={downloadUni}>
                    <div className={styles.icDownload}>
                      <img
                        className={styles.downloadIcon1}
                        alt=""
                        src="/icons/redirectPage/download.svg"
                      />
                    </div>
                    <b className={styles.primaryButton}>
                      Download
                    </b>
                  </button>
                </div>
               
                 <span className = {styles.linkText}> <p > Already have the app? </p> <a onClick = {() => openApp()}> Click to open. </a>  </span> 
              </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default RedirectPageComponent;

