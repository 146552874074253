import { FunctionComponent, useCallback, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import TopBarRemotoWeb from "../../components/TopBarRemotoWeb";
import Footer from "../../components/Footer"

import styles from "./RMTWebsite.module.css";
const RMTWebsite: FunctionComponent = () => {
  const [baseURL, setBaseURL] = useState("");

  const navigate = useNavigate();

  const [env, set_env] = useState('');
  
  const onMenuButton2Click = useCallback(() => {
    navigate("/playback");
  }, []);

  const onMenuButton3Click = useCallback(() => {
    navigate('/download')
  }, []);

  const onMenuButton4Click = useCallback(() => {
    window.open("https://www.linkedin.com/company/remotopro/");
  }, []);

  const onMenuButton5Click = useCallback(() => {
    window.open("https://www.linkedin.com/company/remotopro/");
  }, []);

  const onMenuButton6Click = useCallback(() => {
    navigate('/contact')
  }, []);

  const onText2Click = useCallback(() => {
    window.open("https://www.linkedin.com/company/remotopro/");
  }, []);

  const onText3Click = useCallback(() => {
    window.location.href =
      "mailto:support@remotopro.io?subject=Hi! I´m looking for support";
  }, []);


  const getEnv = async () => {
    let value = "";
    const url = window.location.hostname;
    console.log(url);

    if (url === "localhost") {
      set_env("stage.");
      value = "stage.";
    } else {
      const subdomainValue = url.split(".")[0];

      if (subdomainValue === "beta" || subdomainValue === "stage") {
        set_env(subdomainValue + ".");
        value = subdomainValue + ".";
      }
    }
    console.log(value);
    return value;
  };
  useEffect(()=>{
    getEnv()
    .then((value) => {
      setBaseURL(`https://cdn.${value}remotopro.io`);
    })
  },[])

  return (
    
    <div className={styles.rmtWebsite}>
      <TopBarRemotoWeb />
      <div className={styles.contentsFrame}>
      <h2 className={styles.welcomeToTheContainer} id="title 2">
              <span className={styles.welcomeToThe}>{`Welcome to the `}</span>
              <b>next generation</b>
              <span className={styles.welcomeToThe}>
                {" "}
                of creative collaboration.
              </span>
            </h2>
        
           
          <video className={styles.topVideo}
            src={`${baseURL}/web-assets/Landing/TopBannerRMT+16-9.mp4`}
            autoPlay 
            muted
            playsInline
            loop
            >
          </video>

       
           
           <video className={styles.topVideo_Tall}  
              autoPlay 
             muted
             playsInline
             loop
             >
            <source src={`${baseURL}/web-assets/Landing/TopBannerRMT+4-5.mp4`} type="video/mp4"/>
             
           
           </video>
        
        <div className={styles.featuresFrame}>
            {/* <img
              className={styles.remotoLinesIcon}
              alt="rmt lines"
              loading="lazy"
              src="/icons/indexPage/remoto-lines1.svg"
            /> */}
          <div className={styles.collab}>
            <div className={styles.colaboration}>
              <img
                className={styles.vectorIcon}
                alt=""
                src="/icons/indexPage/1ColabRMT.png"
              />
              <h4 className={styles.colaborationReImagined} id="title 4">
                Collaboration Re-imagined
              </h4>
              <p className={styles.remotoReimaginesAnd} id="parragraph">
                Remoto reimagines and revolutionizes collaborative media
                workflows through innovative and powerful new tools for
                post-production.
              </p>
            </div>
            <img
              className={styles.screencaptureFadeUi}
              alt=""
              src={`${baseURL}/web-assets/Landing/2Collab-REMOTO-500px.png`}
            />
          </div>
          <div className={styles.cuttingEdge}>
            <img
              className={styles.screencaptureFadeUi1}
              alt=""
              src={`${baseURL}/web-assets/Landing/2Driven-REMOTO-500px.png`}
            />
            <div className={styles.div6}>
              <img className={styles.vectorIcon} alt="" src="/icons/indexPage/2DrivenRMT.png" />
              <h4
                className={styles.colaborationReImagined}
                id="title 4"
              >{`Purpose Built`}</h4>
              <p className={styles.remotoReimaginesAnd}>
              Our software is purpose-built from the ground up, powered by cutting edge streaming protocols and content delivery networks. The result is a studio-quality experience and a streamlined, seamless process that meets the needs of everyone from artists to engineers and executives.
              </p>
            </div>
          </div>
          <div className={styles.collab}>
            <div className={styles.colaboration}>
              <img className={styles.vectorIcon} alt="" src="/icons/indexPage/3GrowthRMT.png" />
              <h4 className={styles.colaborationReImagined} id="title 4">
                Unmatched Scalability
              </h4>
              <p className={styles.remotoReimaginesAnd} id="parragraph">
                Remoto solutions are scalable to maximize efficiency,
                productivity, cost savings, and budget flexibility with per-use,
                subscription, or contract pricing.
              </p>
            </div>
            <img
              className={styles.screencaptureFadeUi2}
              alt=""
              src={`${baseURL}/web-assets/Landing/3Growth-REMOTO-500px.png`}
            />
          </div>
          <div className={styles.highlightedSentence}>

          </div>
        </div>
        <div className={styles.whyRemotoFrame}>
          <h1 className={styles.whyRemoto} id="outline title">
            Why Remoto?</h1>
          <video autoPlay muted loop style = {{position: 'absolute', zIndex: 0, alignSelf: 'center', top: 0, width: '100vw', height: 'auto'}} src={`${baseURL}/web-assets/Landing/RemotoLinesBG.mp4`}/>
          <div className={styles.whyRemotoText} id="WhyRemoto UI">
            <div className={styles.remotoUiText} id="container back">
              <img
                className={styles.screencaptureFadeUi3}
                alt="Remoto - The studio experience to wherever you are."
                src={`${baseURL}/web-assets/Landing/why_remoto_UI.png`}
              />
              <div className={styles.textWhyRemotoFrame}>
                <p className={styles.remotoDesignsOurContainer} id="parragraph">
                <div style = {{alignItems: 'center', padding: '.5vh', justifyContent: 'center', flexDirection: 'row', display: 'flex'}}>
                <img style = {{width: '100px', paddingRight: '2vw', height: 'auto'}} src= {`${baseURL}/web-assets/Landing/Remoto-Icon-Web.png`}/>

                  <span className={styles.remotoDesignsOur}>
                  Remoto creates purpose-built solutions as an alternative to tools rushed into the marketplace in recent years to support remote workflows. Our philosophy: start from scratch and do it the right way.                  </span>
                  </div>
                 <br/>
                 <div style = {{alignItems: 'center', padding: '.5vh', justifyContent: 'center', flexDirection: 'row', display: 'flex'}}>
                <img style = {{width: '100px', paddingRight: '2vw', height: 'auto'}} src= {`${baseURL}/web-assets/Landing/Remoto-Icon-Web.png`}/>

                <span className={styles.remotoDesignsOur}>
                Remoto software is designed, built, and tested by audio, video, and post-production professionals. We understand the pressure-filled reality of balancing creative work, file management, security, and tight deadlines. 
                  </span>
                  </div>
                 <br/>
                 <div style = {{alignItems: 'center', padding: '.5vh', justifyContent: 'center', flexDirection: 'row', display: 'flex'}}>
                <img style = {{width: '100px', paddingRight: '2vw', height: 'auto'}} src= {`${baseURL}/web-assets/Landing/Remoto-Icon-Web.png`}/>
                <span className={styles.remotoDesignsOur}>
                Remoto's mission is to enhance remote collaboration so it approaches, and in some ways exceeds, the in-studio experience. Our goal is for your team to feel like they're working together, no matter where they are.
                  </span>
                  </div>
                 <br/>
               
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.scheduleDemoFrame}>
          <div className={styles.frameSched}>
            <div className={styles.textFrame}>
              <h1 style = {{paddingTop: '40px'}}> Schedule a Demo Today!   </h1>
              <p className={styles.wellShowYou} id="parragraph">
              Let us show you how Remoto software can simplify and enhance your media collaboration workflows.
              </p>
            </div>
           
              
              <div onClick={onMenuButton6Click} className={styles.startfreetrial}>
                <div className={styles.button}>
                  <b className={styles.bookADemo}   >{`Contact Us`}</b>
                </div>
              </div>
            
          </div>
        </div>
      
        <Footer/>

      </div>
    </div>
  );
};

export default RMTWebsite;
