import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../context/StoreContext";
import styles from "./auth_flow.module.css";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';


const LoginComponent = () => {
  const { env, getEnv, userToken, setUserToken, setUserAction, setNotifyObject, setChallengeId, userEmail } = useContext(StoreContext);
  const navigate = useNavigate();

  const loginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string()
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
    .matches(/[^A-Za-z0-9]/, 'Password must contain at least one special character.')
    .matches(/[0-9]/, 'Password must contain at least one number.')
    .required('Password is required'),
  });

      const login = async (values) => {
        const { email, password } = values;
        const url = `https://api.${env}remotopro.io/v1/auth/login`;
        const requestOptions = {
          method: 'POST',
          headers: {
            'accept': 'application/json',
            'SolutionContext': 'WEB',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email,
            password: password,
            solution: 'WEB'
          })
        };
      
        try {
          const response = await fetch(url, requestOptions);
          const data = await response.json();

          if (!response.ok) {
            if (response.status == 401){
            setNotifyObject({type: 'error', message: 'Invalid email or password', code: response.status})
            }
            else if(response.status == 423){
            setNotifyObject({type: 'error', message: 'Account is locked', code: response.status})
            }
            else if(response.status == 409){
              setNotifyObject({type: 'error', message: 'Password has expired please reset', code: response.status})
              setUserAction('RESET_PASSWORD');  
            }
            else {
              setNotifyObject({type: 'error', message: data.code.replace(/_/g, ' '), code: response.status})
            }
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          console.log(data)
          if (data.challenge == 'LOGOUT_FROM_OTHER_DEVICES'){
            setChallengeId(data.sessionId)
            setUserAction('LOGOUT_FROM_OTHER_DEVICES')
          }
          if (data.challenge == 'CONFIRM_EMAIL'){
            setUserAction('CONFIRM_ACCOUNT')

          }
          if (data.challenge == 'CHANGE_EXPIRED_PASSWORD'){
            setChallengeId(data.sessionId)
            setNotifyObject({type: 'error', message: 'Password has expired please reset', code: response.status})
            setUserAction('RESET_PASSWORD')
          }
          if (data.challenge == 'CHANGE_PASSWORD'){
            setChallengeId(data.sessionId)
            setNotifyObject({type: 'success', message: 'Please set up your account', code: response.status})
            setUserAction('CHANGE_OT_PASSWORD')
          }
          else{
          if (data.token){
          setUserToken(data.token)
          setNotifyObject({type: 'success', message: 'success', code: ''})
          }
          }
          // Handle the response data as needed
          console.log(data);
        } catch (error) {
          console.error('There was an error!', error);
        }
      };

      useEffect(()=>{
        getEnv();
        if (userToken){
          setUserAction('STRIPE_HANDOFF')
        }

      },[userToken])
      return (
        <div className={styles.mainContainer}>
          <div className={styles.loginCard}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img className={styles.logoImage} src={'Remoto PB 1 line.png'} />
            </div>
            {/* <p style = {{fontSize: '18pt', fontWeight: '300'}}> Login </p>  */}

            <Formik
              initialValues={{ email: userEmail, password: '' }}
              validationSchema={loginSchema}
              onSubmit={(values, { setSubmitting }) => {
                login(values);
                setSubmitting(false);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <label className={styles.inputLabel}>Email</label>
                  <Field name="email" type="text" className={styles.inputContainer} />
                  {errors.email && touched.email ? <div className = {styles.errorText}>{errors.email}</div> : null}
    
                  <div className={styles.hzLabelContainer}>
                    <label className={styles.inputLabel}>Password</label>
                    <div className={styles.hzInputLabel}>
                      <a onClick={() => setUserAction('FORGOT_PASSWORD')}>Forgot Password</a>
                    </div>
                  </div>
    
                  <Field name="password" type="password" className={styles.inputContainer} />
                  {errors.password && touched.password ? <div className = {styles.errorText}>{errors.password}</div> : null}
    
                  <div className={styles.hzLabelContainer} style = {{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <p className={styles.escapeLabel}>
                      Don't have an account? <a className={styles.escapeLink} onClick={() => setUserAction('CREATE_ACCOUNT')}> Sign up </a>
                    </p>
                    <div style = {{display: 'flex', gap: '10px'}}> 
                    <button className={styles.cancelButton} type = "button" onClick={() => navigate(-1)}> Back</button>

                    <button type="submit" className={styles.actionButton}>Sign In</button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {/* <div className={styles.hzNetworkLabel}>
            <p className={styles.escapeLabel}>Not sure about your network settings before streaming?</p>
            <a>Run Network Test here.</a>
          </div> */}
        </div>
      );
}
export default LoginComponent
