export const featureItems = [
    {
        title: 'High quality video live streams',
        text: [
    'Up to 4K at 60fps',
    'Capture source video via NDI, screen capture or hardware (SDI or HDMI using Blackmagic Design capture devices)',
    'Stream from any creative software such as Pro Tools, Final Cut, Avid Media Composer, DaVinci Resolve, Maya, Flame, and Adobe Creative Cloud apps like Premiere Pro and After Effects',
    'Synchronized playback controls make sure everyone sees the same content with ultra-low latency',
  ],
        imagePath: '/icons/featuresPlaybackPage/LiveStreaming.svg',
        imageAlt: 'LiveStreaming'
    },
    {
        title: 'High quality audio',
        text: [
    'Live stream frame-accurate sound editing, mixing and music composition sessions',
    'Capture audio from NDI, SDI or OS level sources, and supports compressed or uncompressed formats',
    'Full spectrum, multi-channel 5.1 or 7.1 surround sound, or up to 16 channels of discrete audio to enable streaming of Dolby Atmos content',
    
  ],
        imagePath: '/icons/featuresPlaybackPage/HQaudio.svg',
        imageAlt: 'HQaudio'
    },
    {
        title: 'Powerful collaboration features',
        text: [
    'Built-in video chat; no need to manage a separate video call',
    'Exportable timecoded markers',
  ],
        imagePath: '/icons/featuresPlaybackPage/CollabIC.svg',
        imageAlt: 'CollabIC'
    },
    {
        title: 'Streamlined for speed',
        text: [
    'Easy-to-use secure dashboard and admin panel',
    'Quickly provision guests and schedule review sessions',
    'Set up sessions in 60 seconds or less',
    'Easily manage your workspace with the flexibility to assign or reassign seats on the fly',
  ],
        imagePath: '/icons/featuresPlaybackPage/Speed.svg',
        imageAlt: 'Speed'
    },
    {
        title: 'Integral, Comprehensive Security',
        text: [
        
    'Instantaneous Media Handling',
    'Secure Connection Assurance',
    'Role-based user security',
    'End-to-end and advanced encryption',
    'Individual guest admittance and multi-factor authentication',
    'TPN, CDSA, GDPR, and HIPAA compliant cloud infrastructure for real-time communications and live streaming'
  ],
        imagePath: '/icons/featuresPlaybackPage/Security.svg',
        imageAlt: 'Security'
    },
    {
        title: 'Powerful Integrations & Perks',
        text: [
    'Dolby.io audio communications APIs dramatically enhance video call quality',
    'No proprietary hardware needed',
    '24/7 white-glove tech support',
  ],
        imagePath: '/icons/featuresPlaybackPage/Addons.svg',
        imageAlt: 'Addons'
    }
]

